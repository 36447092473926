import moment from 'moment'
import {FormInput} from '../../app/interfaces/form-action-interfaces'

export const generateFormLogic = (type: FormInput['type'], value: any, values?: any[]) => {
  switch (type) {
    case 'text-area':
      return value
    // return jsonEscape(value)
    case 'select-with-text':
      if (value !== null) {
        return value['value']
      }
      return ''
    // return value['value']
    case 'select-with-text-multi':
      let ids = []
      if (value.length > 0) {
        ids = value.map((v: any, k: any) => v.value)
      }
      return ids
    case 'select-number':
      return +value
    case 'number':
      return +value
    case 'date':
      // console.log("asdasd");
      return moment(value).format('YYYY-MM-DD') ?? ''
    case 'datetime':
      // console.log("asdasd", moment(value).format("YYYY-MM-DD H:mm:ss"))
      return moment(value).format('YYYY-MM-DD H:mm:ss')
    // case 'time':
    // return moment(value).format('H:mm')
    case 'datetime-blast':
      // console.log("asdasd", moment(value).format("YYYY-MM-DD H:mm:ss"))
      return moment(value).format('YYYY-MM-DD H:mm:ss +0700')
    case 'multiple-text':
      const items = []
      for (const item of values as string[]) {
        items.push({
          description: item,
        })
      }
      return items
    default:
      return value
  }
}

export const generateValueLogic = (type: FormInput['type'], value: any) => {
  console.log('zxczxc', type, value)
  switch (type) {
    case 'select-with-text':
      return value['value']
    case 'date':
      if (value === '') return ''
      if (value === 'Invalid date') return moment(Date.now())?.toDate()
      return moment(value)?.toDate() ?? moment(Date.now())?.toDate()
    case 'datetime':
      if (value === '') return ''
      return moment(value).format('YYYY-MM-DD H:mm:ss')
    default:
      return value
  }
}

export const generateInitValue = (type: FormInput['type'], value: any) => {
  switch (type) {
    case 'select-with-text':
      return value['value']
    case 'date':
      if (value === '') return ''
      if (value === 'Invalid date') return moment(Date.now())?.toDate()
      return moment(value)?.toDate() ?? moment(Date.now())?.toDate()
    case 'datetime':
      if (value === '') return ''
      return moment(value).format('YYYY-MM-DD H:mm:ss')
    default:
      return value
  }
}
