import {create} from 'zustand'
import {
  CreateBulkMasterExpense,
  CreateBulkMasterExpenseDetail,
  GetAllMasterExpenseDetail,
  GetAllMasterExpenseParam,
} from '../providers/master-expense.provider'
import {toast} from 'react-toastify'
import {
  MasterDetailExpenseDataItem,
  MasterDetailExpenseResultInterface,
} from '../interfaces/master-expenses-details-result.interface'

export interface MasterExpenseDetail {
  periode_id: string
  department_id: string
  division_id: string
  user_id: string
  details: MasterExpenseDetailData[]
}

export interface MasterExpenseDetailData {
  title: string
  description: string
  month: number
  items: MasterExpenseDetailItems[]
}

export interface MasterExpenseDetailItems {
  title: string
  description: string
  qty: number
  vol: number
  amount: number
  grand_total: number
}

interface MasterExpenseDetailState {
  detail: MasterExpenseDetail
  setDetail: (data: MasterExpenseDetail) => void

  details: MasterExpenseDetailData[]
  setDetails: (data: MasterExpenseDetailData) => void

  submitLoading: boolean
  submitMasterExpenseDetailBulk: () => Promise<void>

  getMasterDetails: (params: GetAllMasterExpenseParam) => Promise<void>
  masterDetailModel: MasterDetailExpenseResultInterface
  masterDetails: MasterDetailExpenseDataItem[]
  masterDetailLoading: boolean

  setMasterDetail: (data: MasterDetailExpenseDataItem) => void
  masterDetail: MasterDetailExpenseDataItem

  reset: () => void
}

export const useMasterExpenseDetailStore = create<MasterExpenseDetailState>((set, get) => ({
  reset: () => {
    set({
      detail: undefined,
      details: [],
      masterDetail: undefined,
      submitLoading: false,
    })
  },
  detail: undefined,
  setDetail: (data) => {
    set({detail: data})
  },
  details: [],
  setDetails: (data) => {
    const details = [...get().details]
    const detailIndex = details.findIndex((item) => item.month === data.month)
    if (detailIndex === -1) {
      details.push(data)
    } else {
      details[detailIndex] = data
    }
    set({details: details})
  },

  submitLoading: false,
  submitMasterExpenseDetailBulk: async () => {
    set({submitLoading: true})
    let grandTotal = 0
    for (const detail of get().details) {
      for (const item of detail.items) {
        grandTotal += item.grand_total
      }
    }

    const forms = {
      periode_id: get().detail?.periode_id,
      department_id: get().detail?.department_id,
      division_id: get().detail?.division_id,
      user_id: get().detail?.user_id,
      month: 0,
      title: '',
      description: '',
      details: get().details,
      grand_total: grandTotal,
    }
    const response = await CreateBulkMasterExpenseDetail(forms)
    if (response.status === false) {
      toast.error('Error went submitting data')
    }
    set({submitLoading: false})
  },
  masterDetailModel: undefined,
  masterDetails: [],
  masterDetailLoading: false,
  getMasterDetails: async (params: GetAllMasterExpenseParam) => {
    set({masterDetails: [], masterDetailLoading: true, masterDetailModel: undefined})
    const response = await GetAllMasterExpenseDetail(params)
    set({masterDetailModel: response})
    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({masterDetails: response.data?.items})
      }
    }
    set({masterDetailLoading: false})
  },

  setMasterDetail: (data) => {
    set({
      details: data.details,
      masterDetail: data,
      detail: {
        periode_id: data?.id,
        department_id: data?.department_id,
        division_id: data?.division_id,
        user_id: data?.user_id,
        details: data.details,
      },
    })
  },
  masterDetail: undefined,
}))
