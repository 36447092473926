import {AxiosError} from 'axios'
import {ApiFinance} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {PeriodeResultInterface, PeriodeData} from '../interfaces/periode-result.interface'
import {PeriodeSingleResultInterface} from '../interfaces/periode-single-result.interface'

export interface GetAllPeriodeParam {
  page?: number
  size?: number
}

export const GetAllPeriode = async (
  params: GetAllPeriodeParam
): Promise<PeriodeResultInterface> => {
  try {
    let response = await ApiFinance.get('/periodes', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: PeriodeResultInterface = a.response?.data
    return response
  }
}

export const ShowPeriode = async (id: string): Promise<PeriodeSingleResultInterface> => {
  try {
    let response = await ApiFinance.get('/periodes/' + id)
    return response.data as PeriodeSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: PeriodeSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitPeriode = async (form: PeriodeData): Promise<PeriodeSingleResultInterface> => {
  try {
    let response = await ApiFinance.post('/periodes', form)
    return response.data as PeriodeSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: PeriodeSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdatePeriode = async (
  id: string,
  form: PeriodeData
): Promise<PeriodeSingleResultInterface> => {
  try {
    let response = await ApiFinance.put('/periodes/' + id, form)
    return response.data as PeriodeSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: PeriodeSingleResultInterface = a.response?.data
    return response
  }
}

export const DeletePeriode = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiFinance.delete('/periodes/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
