import {AxiosError} from 'axios'
import {ApiFinance} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {MasterExpenseResultInterface} from '../interfaces/master-expenses-result.interface'
import {MasterExpenseTarget} from '../states/master-expense.state'
import {MasterDetailExpenseResultInterface} from '../interfaces/master-expenses-details-result.interface'

export interface GetAllMasterExpenseParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
}

export const CreateBulkMasterExpense = async (
  forms: MasterExpenseTarget[]
): Promise<GeneralInterface> => {
  try {
    let response = await ApiFinance.post('/master-expenses-bulk', forms)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
export const CreateBulkMasterExpenseDetail = async (forms: any): Promise<GeneralInterface> => {
  try {
    let response = await ApiFinance.post('/master-expenses-details', forms)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetAllMasterExpense = async (
  params: GetAllMasterExpenseParam
): Promise<MasterExpenseResultInterface> => {
  try {
    let response = await ApiFinance.get('/master-expenses', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: MasterExpenseResultInterface = a.response?.data
    return response
  }
}

export const GetAllMasterExpenseDetail = async (
  params: GetAllMasterExpenseParam
): Promise<MasterDetailExpenseResultInterface> => {
  try {
    let response = await ApiFinance.get('/master-expenses-details', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: MasterDetailExpenseResultInterface = a.response?.data
    return response
  }
}
