import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { dateTimeFormatter } from "../../../../../../_metronic/helpers/datetime-formatter";
import { generateImageUrl } from "../../../../../../_metronic/helpers/generate-image-url";
import { generateCurrencyFormat } from "../../../../../helpers/generate-currency-format";
import { LoadingComponent } from "../../../crm/properties-management/components/loading.component";
import { useProgramStore } from "../../../program/program/states/program.state";
import { useTransactionPaymentFormStore } from "../states/transaction-payment.state";

interface Props {
  onCloseModal: () => void;
}

const ProgramListModalComponent: React.FC<Props> = ({
  onCloseModal
}) => {
  
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, []);

  return (
    <>
    <div 
        className="modal fade show d-block"
        id="kt_modal_properties"
        role='dialog'
        tabIndex={-1}
        aria-modal='true'>
        <div className="modal-dialog modal-dialog-centered mw-800px">
          <div className="modal-content">
            <ProgramListModalHeader
              onCloseModal={onCloseModal}
            />

            <div className="modal-body scroll-y">
              <ProgramListModalWrapper
                onCloseModal={onCloseModal}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  );
}

const ProgramListModalHeader: React.FC<Props> = ({
  onCloseModal
}) => {
  return (
    <div className="modal-header">
      <h2 className="fw-bold">
        {'Pilih Donasi'}
      </h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={onCloseModal}
        style={{cursor: 'pointer'}}>
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  );
}

const ProgramListModalWrapper: React.FC<Props> = ({ onCloseModal }) => {

  const { getPrograms, programItems, programLoading, programModel } = useProgramStore();
  const { setSelectedProgram } = useTransactionPaymentFormStore();
  
  useEffect(() => {
    getPrograms({ page: 0, size: 10 });
  }, [])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    getPrograms({ page: selectedPage, size: 10 });
  }

  return (
    <>
      <div
        className='d-flex flex-column scroll-y'
        id='kt_modal_add_user_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
        data-kt-scroll-offset='300px'>
        <div className="table-responsive">
          {
            programLoading ? <LoadingComponent/> : <></>
          }
          <table className="table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3">
            <thead>
              <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                <th>Media</th>
                <th>Title</th>
                <th>Program Target</th>
                <th>Started At</th>
                <th>Finished At</th>
                <th className='text-end'>Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 fw-semibold">
            {
              programItems.length === 0
              ? (
                <tr>
                  <td colSpan={6}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No Properties Found
                    </div>
                  </td>
                </tr>
              )
              : programItems?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                        <img
                          src={generateImageUrl(item.banner)}
                          alt={item.id}
                          style={{
                            borderRadius: 10,
                            width: 150,
                            height: 75,
                            objectFit: 'cover'
                          }}
                        />
                      </td>
                      <td>
                        {item.title}
                      </td>
                      <td>{generateCurrencyFormat(item.donation_target)}</td>
                      <td>{dateTimeFormatter(Date.parse(item.started_at.toString()))}</td>
                      <td>{dateTimeFormatter(Date.parse(item.finished_at.toString()))}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={e => {
                          setSelectedProgram(item);
                          onCloseModal();
                        }}>
                        Pilih
                      </button>
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>

        <ReactPaginate
            previousLabel={"«"}
            nextLabel={"»"}
            breakLabel={"..."}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            pageCount={programModel?.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={"pagination pagination-sm m-0 float-right"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
      </div>
    </>
  );
}

export default ProgramListModalComponent;