import {useNavigate, useParams} from 'react-router-dom'
import {KTCard} from '../../../../../../_metronic/helpers'
import {useEffect, useRef} from 'react'
import {usePeriodeStore} from '../../periodes/states/periode.state'
import {dateTimeFormatter} from '../../../../../../_metronic/helpers/datetime-formatter'
import {useDepartmentStore} from '../../departments/states/department.state'
import {InputCurrency} from '../../../../forms/components/input.component'
import {useMasterExpenseStore} from '../states/master-expense.state'
import {NumberFormatValues, SourceInfo} from 'react-number-format'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import MoonLoader from 'react-spinners/MoonLoader'

const MasterExpenseListComponent = () => {
  const params = useParams()
  const {getPeriode, periodeItem, monthDiff} = usePeriodeStore()
  const {getDepartments, departmentItems} = useDepartmentStore()
  const tableRef = useRef<HTMLDivElement>(null)
  const {
    targets,
    setTargets,
    totalByDivisions,
    totalByMonth,
    grandTotal,
    submitLoading,
    submitMasterExpenseBulk,
    getMasterExpenses,
    masterExpenseLoading,
    reset,
  } = useMasterExpenseStore()
  const navigate = useNavigate()

  // detect first render
  const firstRender = useRef(true)

  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (firstRender.current) {
        firstRender.current = false
        return
      }

      submitMasterExpenseBulk()
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [targets])

  useEffect(() => {
    if (params['id'] !== undefined) {
      getPeriode(params['id'] as string)
      getDepartments({
        page: 0,
        size: 1000,
        filters: `["department_type", "cost"]`,
        sort: 'department_name',
      })
      getMasterExpenses({
        page: 0,
        size: 100000,
        filters: `["periode_id", "${params['id'] as string}"]`,
      })
    }
  }, [params])

  const handleScroll = () => {
    const table = tableRef.current
    if (table) {
      const {scrollTop, scrollLeft} = table
      const frozenColumns = table.querySelectorAll('.frozen-column')
      frozenColumns.forEach((column) => {
        if (scrollLeft > 0) {
          column.classList.add('frozen-column-shadow')
        } else {
          column.classList.remove('frozen-column-shadow')
        }
      })
    }
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-1 pt-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Periode {dateTimeFormatter(Date.parse(periodeItem?.start_date?.toString()))} -{' '}
              {dateTimeFormatter(Date.parse(periodeItem?.finish_date?.toString()))}
            </span>
          </h3>
        </div>
      </KTCard>
      {masterExpenseLoading ? (
        <MoonLoader size={12} />
      ) : (
        <KTCard>
          <div className='card-header border-1 pt-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Master Expense</span>
              {submitLoading ? (
                <div
                  className='d-flex flex-row'
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <MoonLoader size={12} />
                  <span className='mx-2' style={{fontSize: 12, fontWeight: 'bold'}}>
                    Saving
                  </span>
                </div>
              ) : null}
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive' ref={tableRef} onScroll={handleScroll}>
              <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                <thead>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <th>Department</th>
                    <th className='frozen-column'>Target</th>
                    {monthDiff?.map((item, index) => {
                      return (
                        <th key={index} className='min-w-175px'>
                          {item.monthName}
                        </th>
                      )
                    })}
                    <th className='frozen-column'>Total</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                  {departmentItems?.map((item, index) => {
                    return item.divisions?.map((itemm, indexx) => {
                      return (
                        <tr key={indexx}>
                          <td style={{fontSize: 14, fontWeight: 'bold'}}>{item.department_name}</td>
                          <td
                            className='frozen-column'
                            style={{fontSize: 14, fontWeight: 'bold', cursor: 'pointer'}}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate('details', {
                                state: {
                                  division: itemm,
                                  periode: periodeItem,
                                },
                              })
                            }}
                          >
                            {itemm.division_name}
                          </td>
                          {monthDiff?.map((item, index) => {
                            return (
                              <td key={index} className='min-w-175px'>
                                <InputCurrency
                                  id={'amount'}
                                  title=''
                                  placeholder={'Target...'}
                                  name={'amount'}
                                  type={'currency'}
                                  value={
                                    +targets.find(
                                      (data) =>
                                        data.month === item?.month && data.division_id === itemm.id
                                    )?.amount
                                  }
                                  onValueChange={(
                                    values: NumberFormatValues,
                                    sourceInfo: SourceInfo
                                  ) => {
                                    setTargets({
                                      division_id: itemm.id,
                                      department_id: itemm.department_id,
                                      amount: values.floatValue,
                                      month: item?.month,
                                      periode_id: periodeItem?.id,
                                    })
                                  }}
                                />
                              </td>
                            )
                          })}
                          <td>
                            <span style={{fontWeight: 'bold', fontSize: 14}}>
                              {generateCurrencyFormat(
                                isNaN(
                                  +totalByDivisions?.find((data) => data.division_id === itemm.id)
                                    ?.total_amount
                                )
                                  ? 0
                                  : +totalByDivisions?.find((data) => data.division_id === itemm.id)
                                      ?.total_amount
                              )}
                            </span>
                          </td>
                        </tr>
                      )
                    })
                  })}
                  <tr>
                    <td></td>
                    <td className='frozen-column'>Total</td>
                    {monthDiff?.map((item, index) => {
                      return (
                        <th key={index} className='min-w-175px'>
                          <span style={{fontSize: 14, fontWeight: 'bold'}}>
                            {generateCurrencyFormat(
                              isNaN(
                                +totalByMonth?.find((data) => data.month === item.month)
                                  ?.total_amount
                              )
                                ? 0
                                : +totalByMonth?.find((data) => data.month === item.month)
                                    ?.total_amount
                            )}
                          </span>
                        </th>
                      )
                    })}
                    <td>
                      <span style={{fontSize: 14, fontWeight: 'bold'}}>
                        {generateCurrencyFormat(grandTotal)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </KTCard>
      )}
    </>
  )
}

const MasterExpenseListWrapper = () => {
  return (
    <>
      <MasterExpenseListComponent />
    </>
  )
}

export default MasterExpenseListWrapper
