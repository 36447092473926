import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../../_metronic/layout/core'
import MasterExpenseListWrapper from '../components/master-expense-list.screen'
import MasterExpenseDetailWrapper from '../components/master-expense-detail-list.screen'
import MasterExpenseDetailFormWrapper from '../components/master-expense-detail-form.screen'

const masterExpensesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Master Expense Management',
    path: '/master/finance/master-expense-management/master-expenses-periode/:id',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MasterExpensePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='master-expenses-periode/:id'
          element={
            <>
              <PageTitle breadcrumbs={masterExpensesBreadcrumbs}>Master Expense List</PageTitle>
              <MasterExpenseListWrapper />
            </>
          }
        />
        <Route
          path='master-expenses-periode/:id/details'
          element={
            <>
              <PageTitle breadcrumbs={masterExpensesBreadcrumbs}>
                Master Expense Detail List
              </PageTitle>
              <MasterExpenseDetailWrapper />
            </>
          }
        />
        <Route
          path='master-expenses-periode/:id/details/forms'
          element={
            <>
              <PageTitle breadcrumbs={masterExpensesBreadcrumbs}>
                Master Expense Detail Form
              </PageTitle>
              <MasterExpenseDetailFormWrapper />
            </>
          }
        />
      </Route>
      <Route
        index
        element={
          <Navigate to='/master/finance/master-expense-management/master-expenses-periode/:id' />
        }
      />
    </Routes>
  )
}

export default MasterExpensePage
