import {AxiosError} from 'axios'
import {ApiFinance} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {DivisionResultInterface, DivisionData} from '../interfaces/division-result.inteface'
import {DivisionSingleResultInterface} from '../interfaces/division-single-result.interface'
import {DashboardDivisionTargetAndRealizationResult} from '../interfaces/dashboard-division-target-and-realization-result.interface'

export interface GetAllDivisionParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
}

export const GetAllDivision = async (
  params: GetAllDivisionParam
): Promise<DivisionResultInterface> => {
  try {
    let response = await ApiFinance.get('/divisions', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DivisionResultInterface = a.response?.data
    return response
  }
}

export const ShowDivision = async (id: string): Promise<DivisionSingleResultInterface> => {
  try {
    let response = await ApiFinance.get('/divisions/' + id)
    return response.data as DivisionSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DivisionSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitDivision = async (
  form: DivisionData
): Promise<DivisionSingleResultInterface> => {
  try {
    let response = await ApiFinance.post('/divisions', form)
    return response.data as DivisionSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DivisionSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateDivision = async (
  id: string,
  form: DivisionData
): Promise<DivisionSingleResultInterface> => {
  try {
    let response = await ApiFinance.put('/divisions/' + id, form)
    return response.data as DivisionSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DivisionSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteDivision = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiFinance.delete('/divisions/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetDashboardDivisionTargetAndRealization = async (
  startDate: string,
  endDate: string,
  periodeId: string,
  departmentType: string
): Promise<DashboardDivisionTargetAndRealizationResult> => {
  try {
    let response = await ApiFinance.get('/divisions-dashboard', {
      params: {
        start_date: startDate,
        end_date: endDate,
        periode_id: periodeId,
        department_type: departmentType,
      },
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
