import {AxiosError} from 'axios'
import {ApiFinance} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {
  ChartOfAccountResultInterface,
  ChartOfAccountResultNonPagingInterface,
} from '../interfaces/chart-of-account-result.interface'
import {ChartOfAccountSingleResultInterface} from '../interfaces/chart-of-account-single-result.interface'
import {ChartOfAccountTreeResultInterface} from '../interfaces/chart-of-account-tree-result.interface'

export interface GetAllChartOfAccountParam {
  page?: number
  size?: number
  is_group?: boolean
}

export const GetAllChartOfAccountTree = async (
  query: string
): Promise<ChartOfAccountTreeResultInterface> => {
  try {
    let response = await ApiFinance.get(`/chart-of-accounts-tree`, {
      params: {
        query: query,
      },
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ChartOfAccountTreeResultInterface = a.response?.data
    return response
  }
}

export const GetAllChartOfAccount = async (
  params: GetAllChartOfAccountParam
): Promise<ChartOfAccountResultInterface> => {
  try {
    let response = await ApiFinance.get('/chart-of-accounts', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ChartOfAccountResultInterface = a.response?.data
    return response
  }
}

export const GetAllChartOfAccountAsset =
  async (): Promise<ChartOfAccountResultNonPagingInterface> => {
    try {
      let response = await ApiFinance.get('/chart-of-accounts-transfer-banks')
      return response.data
    } catch (e) {
      let a = e as AxiosError
      let response: ChartOfAccountResultNonPagingInterface = a.response?.data
      return response
    }
  }

export const GetAllChartOfAccountReceivable =
  async (): Promise<ChartOfAccountResultNonPagingInterface> => {
    try {
      let response = await ApiFinance.get('/chart-of-accounts-receivable')
      return response.data
    } catch (e) {
      let a = e as AxiosError
      let response: ChartOfAccountResultNonPagingInterface = a.response?.data
      return response
    }
  }

export const GetAllChartOfAccountPayable =
  async (): Promise<ChartOfAccountResultNonPagingInterface> => {
    try {
      let response = await ApiFinance.get('/chart-of-accounts-payable')
      return response.data
    } catch (e) {
      let a = e as AxiosError
      let response: ChartOfAccountResultNonPagingInterface = a.response?.data
      return response
    }
  }

export const GetAllChartOfAccountExpense =
  async (): Promise<ChartOfAccountResultNonPagingInterface> => {
    try {
      let response = await ApiFinance.get('/chart-of-accounts-expenses')
      return response.data
    } catch (e) {
      let a = e as AxiosError
      let response: ChartOfAccountResultNonPagingInterface = a.response?.data
      return response
    }
  }

export const ShowChartOfAccount = async (
  id: string
): Promise<ChartOfAccountSingleResultInterface> => {
  try {
    let response = await ApiFinance.get('/chart-of-accounts/' + id)
    return response.data as ChartOfAccountSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ChartOfAccountSingleResultInterface = a.response?.data
    return response
  }
}

export const ShowChartOfAccountByCode = async (
  coaCode: string
): Promise<ChartOfAccountSingleResultInterface> => {
  try {
    let response = await ApiFinance.get('/chart-of-accounts-by-coa-code/' + coaCode)
    return response.data as ChartOfAccountSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ChartOfAccountSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitChartOfAccount = async (
  form: any
): Promise<ChartOfAccountSingleResultInterface> => {
  try {
    let response = await ApiFinance.post('/chart-of-accounts', form)
    return response.data as ChartOfAccountSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ChartOfAccountSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateChartOfAccount = async (
  id: string,
  form: any
): Promise<ChartOfAccountSingleResultInterface> => {
  try {
    let response = await ApiFinance.put('/chart-of-accounts/' + id, form)
    return response.data as ChartOfAccountSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ChartOfAccountSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteChartOfAccount = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiFinance.delete('/chart-of-accounts/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
