import moment from 'moment'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {allowedMessageAttachment} from '../../../../../../_metronic/helpers/allowed-message-attachment'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import {SelectData} from '../../../../../interfaces/form-action-interfaces'
import {FileUploadV2} from '../../../../forms/components/file-upload-v2.component'
import {
  CheckboxInput,
  Input,
  InputDatePicker,
  InputTextArea,
  SelectWithText,
} from '../../../../forms/components/input.component'
import {useChartOfAccountStore} from '../../chart-of-accounts/states/chart-of-account.state'
import ProgramListModalComponent from './program-list-modal.component'
import TransactionPaymentDetailModalComponent from './transaction-payment-detail-modal.component'
import {useTransactionFormStore} from '../states/transaction-payment-v2.state'
import {TransactionData} from '../../transactions/interfaces/transaction-result.interface'
import {useDepartmentStore} from '../../departments/states/department.state'
import {useDivisionStore} from '../../divisions/states/division.state'

const TransactionPaymentFormComponent = () => {
  const [isDonation, setIsDonation] = useState<boolean>(false)
  const [openDonasi, setOpenDonasi] = useState<boolean>(false)
  const [openForm, setOpenForm] = useState<boolean>(false)
  const {
    getSelectedProgramByProgramId,
    selectedProgram,
    transactionFormReset,
    postedDate,
    setPostedDate,
    remarks,
    setRemarks,
    setSelectedTransaction,
    selectedTransaction,
    setDocument,
    document,
    transactionAccounts,
    submitDone,
    department,
    setDepartment,
    division,
    setDivision,
    status,
    setStatus,
  } = useTransactionFormStore()
  const location = useLocation()
  const navigate = useNavigate()
  const {getDepartments, departmentSelectDatas} = useDepartmentStore()
  const {getDivisions, divisionSelectDatas} = useDivisionStore()

  useEffect(() => {
    getDepartments({page: 0, size: 10000, sort: 'department_name'})

    return () => {
      transactionFormReset()
      setSelectedTransaction(undefined)
    }
  }, [])

  useEffect(() => {
    if (submitDone !== undefined && submitDone === true) {
      navigate(-1)
    }
  }, [submitDone])

  useEffect(() => {
    if (location) {
      if (location.state !== null) {
        setSelectedTransaction(location.state as TransactionData)
      }
    }
  }, [location])

  useEffect(() => {
    console.log('qweqwe', selectedTransaction)
  }, [selectedTransaction])

  useEffect(() => {
    console.log(isDonation)
    if (!isDonation) {
      transactionFormReset()
    }
  }, [isDonation])

  useEffect(() => {
    console.log('dsadsa', selectedTransaction)
    if (selectedTransaction !== undefined) {
      if (selectedTransaction?.program_id !== null && selectedTransaction?.program_id !== '') {
        setIsDonation(true)
        getSelectedProgramByProgramId(selectedTransaction?.program_id)
      }
      setPostedDate(moment(selectedTransaction?.posted_date).toDate())
      setRemarks(selectedTransaction?.remarks)
      setDocument(selectedTransaction?.document)
      setDepartment({
        label: selectedTransaction?.department?.department_name,
        value: selectedTransaction?.department?.id,
      })
      setDivision({
        label: selectedTransaction?.division?.division_name,
        value: selectedTransaction?.division?.id,
      })
      setStatus({
        label: selectedTransaction?.status,
        value: selectedTransaction?.status,
      })
    }
  }, [selectedTransaction])

  useEffect(() => {
    console.log(transactionAccounts)
  }, [transactionAccounts])

  return (
    <>
      <div className='row g-5 gx-xxl-12'>
        <div className='col-sm-4 col-md-4 col-lg-4 col-xxl-4'>
          <KTCard>
            <div className='card-header border-0 pt-6'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Data</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>Data Pengeluaran</span>
              </h3>
            </div>
            <div className='card-body py-3'>
              <div className='w-100'>
                <div className='mb-10 fv-row fv-plugins-icon-container'>
                  {/* <CheckboxInput
                    id={'is_donation'}
                    title={'Apakah Donasi?'}
                    name={'is_donation'}
                    type={'checkbox'}
                    value={isDonation}
                    onChange={(e) => setIsDonation(e.target.checked)}
                  />
                  {isDonation ? (
                    <>
                      <div className={`fv-row mb-7`}>
                        <label className={`d-block fw-semibold fs-6 mb-5 `}>
                          {'Pilih Program yang akan di Link'}
                        </label>
                        <button
                          className='btn btn-sm btn-primary'
                          onClick={(e) => {
                            e.preventDefault()
                            setOpenDonasi(!openDonasi)
                          }}
                        >
                          Pilih Program
                        </button>
                      </div>
                      {selectedProgram !== undefined ? (
                        <>
                          <Input
                            id={'program_id'}
                            title={'Program'}
                            name={'program_id'}
                            type={'text'}
                            value={selectedProgram?.title}
                            disabled={true}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )} */}
                  <InputDatePicker
                    id={'posted_date'}
                    title={'Tanggal'}
                    placeholder={'Tanggal...'}
                    name={'posted_date'}
                    type={'date'}
                    value={postedDate}
                    onChangeDate={(e) => {
                      setPostedDate(e)
                    }}
                  />
                  <InputTextArea
                    id={'remarks'}
                    title={'Catatan'}
                    type={'text-area'}
                    placeholder={'Catatan...'}
                    name={'remarks'}
                    value={remarks}
                    disabled={false}
                    onChangeTextArea={(e) => {
                      setRemarks(e.target.value)
                    }}
                  />
                  <SelectWithText
                    title={'Department'}
                    name={'department_id'}
                    selectData={departmentSelectDatas}
                    type={'select-with-text'}
                    value={department}
                    disabled={false}
                    id={'department_id'}
                    onChangeSelectWithText={(e) => {
                      setDepartment(e)
                      getDivisions({
                        page: 0,
                        size: 10000,
                        filters: `["department_id", "${e.value}"]`,
                      })
                    }}
                  />
                  <SelectWithText
                    title={'Divisi'}
                    name={'division_id'}
                    selectData={divisionSelectDatas}
                    type={'select-with-text'}
                    value={division}
                    disabled={false}
                    id={'division_id'}
                    onChangeSelectWithText={(e) => {
                      setDivision(e)
                    }}
                  />
                  <SelectWithText
                    title={'Status'}
                    name={'status'}
                    selectData={[
                      {
                        label: 'Unpaid',
                        value: 'Unpaid',
                      },
                      {
                        label: 'Release',
                        value: 'Release',
                      },
                    ]}
                    type={'select-with-text'}
                    value={status}
                    disabled={false}
                    id={'status'}
                    onChangeSelectWithText={(e) => {
                      setStatus(e)
                    }}
                  />
                  <FileUploadV2
                    title={'Document'}
                    multiple={false}
                    description={'Choose file'}
                    name={'document'}
                    onUploadDone={(e) => {
                      setDocument(e.data?.id)
                    }}
                    value={document}
                    disabled={false}
                    isImage={false}
                    accept={allowedMessageAttachment}
                    required={false}
                    onChangeFile={(e) => {}}
                  />
                </div>
              </div>
            </div>
          </KTCard>
        </div>

        <TransactionPaymentDetailForm openForm={openForm} setOpenForm={setOpenForm} />
      </div>

      {openDonasi && <ProgramListModalComponent onCloseModal={() => setOpenDonasi(!openDonasi)} />}
      {openForm && (
        <TransactionPaymentDetailModalComponent onCloseModal={() => setOpenForm(!openForm)} />
      )}
    </>
  )
}

interface Props {
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>
  openForm: boolean
}

const TransactionPaymentDetailForm: React.FC<Props> = ({setOpenForm, openForm}) => {
  const {removeTransactionAccounts, transactionAccounts, onSubmit, selectedTransaction} =
    useTransactionFormStore()
  const {getCoasPayable, coaPayableSelectData} = useChartOfAccountStore()
  const [creditCoa, setCreditCoa] = useState<SelectData>()
  const [creditCoas, setCreditCoas] = useState<SelectData[]>([])

  useEffect(() => {
    getCoasPayable()
  }, [])

  useEffect(() => {
    if (coaPayableSelectData?.length > 0) {
      setCreditCoas(coaPayableSelectData)
    }
  }, [coaPayableSelectData])

  useEffect(() => {
    if (creditCoas.length > 0) {
      let debit: SelectData = {}
      for (const item of transactionAccounts) {
        debit = coaPayableSelectData?.find((el) => el.value === item.chart_of_account_id)
        if (debit !== undefined) {
          setCreditCoa(debit)
          break
        }
      }
    }
  }, [creditCoas])

  return (
    <div className='col-sm-8 col-md-8 col-lg-8 col-xxl-8'>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Detail</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Pembukuan</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setOpenForm(true)
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Tambah Data
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col-md-12'>
              <SelectWithText
                id={'cash_bank'}
                title={'Kas/Bank'}
                name={'cash_bank'}
                type={'select-with-text'}
                selectData={creditCoas}
                value={creditCoa}
                onChangeSelectWithText={(e) => {
                  setCreditCoa(e)
                }}
              />
            </div>
          </div>
          <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th>COA Code</th>
                <th>Name</th>
                <th>Position</th>
                <th>Amount</th>
                <th className='text-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {transactionAccounts?.map((item, index) => {
                if (item.chart_of_account_id !== creditCoa?.value) {
                  return (
                    <tr key={index}>
                      <td>{item.chart_of_account?.coa_code}</td>
                      <td>{item.chart_of_account?.name}</td>
                      <td>{item.position}</td>
                      <td>{generateCurrencyFormat(item.amount)}</td>
                      <td className='text-end'>
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          onClick={(e) => {
                            e.preventDefault()
                            removeTransactionAccounts(index)
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </td>
                    </tr>
                  )
                }
                return <></>
              })}
            </tbody>
          </table>
        </div>
        <div className='card-footer d-flex justify-content-end'>
          <button
            className='btn btn-primary'
            onClick={(e) => {
              e.preventDefault()
              console.log('cxzcxz', selectedTransaction)
              onSubmit(creditCoa?.value)
            }}
          >
            Submit
          </button>
        </div>
      </KTCard>
    </div>
  )
}

export default TransactionPaymentFormComponent
