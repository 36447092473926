import {AxiosError} from 'axios'
import {ApiFinance} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {DepartmentResultInterface, DepartmentData} from '../interfaces/department-result.interface'
import {DepartmentSingleResultInterface} from '../interfaces/department-single-result.interface'

export interface GetAllDepartmentParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
}

export const GetAllDepartment = async (
  params: GetAllDepartmentParam
): Promise<DepartmentResultInterface> => {
  try {
    let response = await ApiFinance.get('/departments', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DepartmentResultInterface = a.response?.data
    return response
  }
}

export const ShowDepartment = async (id: string): Promise<DepartmentSingleResultInterface> => {
  try {
    let response = await ApiFinance.get('/departments/' + id)
    return response.data as DepartmentSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DepartmentSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitDepartment = async (
  form: DepartmentData
): Promise<DepartmentSingleResultInterface> => {
  try {
    let response = await ApiFinance.post('/departments', form)
    return response.data as DepartmentSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DepartmentSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateDepartment = async (
  id: string,
  form: DepartmentData
): Promise<DepartmentSingleResultInterface> => {
  try {
    let response = await ApiFinance.put('/departments/' + id, form)
    return response.data as DepartmentSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DepartmentSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteDepartment = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiFinance.delete('/departments/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
