import {create} from 'zustand'
import {
  AccountType,
  ChartOfAccountProfitAndLoss,
  ProfitAndLossProgram,
  ProfitAndLossResultInterface,
} from '../interfaces/profit-and-loss-result.interface'
import {GetAllProfitAndLoss, GetAllProfitAndLossParam} from '../providers/profit-and-loss.provider'

interface ProfitAndLossState {
  profitAndLossLoading: boolean
  profitAndLossPrograms: ProfitAndLossProgram[]
  accountTypes: AccountType[]
  profitAndLossModel: ProfitAndLossResultInterface
  getProfitAndLosss: (params: GetAllProfitAndLossParam) => void
  resetPnL: () => void
}

export const useProfitAndLossStore = create<ProfitAndLossState>((set, get) => ({
  profitAndLossLoading: false,
  accountTypes: [],
  profitAndLossPrograms: [],
  profitAndLossModel: undefined,
  getProfitAndLosss: async (params: GetAllProfitAndLossParam) => {
    set({profitAndLossLoading: true})
    const response = await GetAllProfitAndLoss(params)
    set({profitAndLossModel: response})

    if (response.status) {
      if (response.data?.account_types?.length > 0) {
        // set({
        //   accountTypes: response.data.account_types,
        // })
      }
      if (response.data?.profit_and_loss_programs?.length > 0) {
        set({
          profitAndLossPrograms: response.data.profit_and_loss_programs,
        })

        const pnlPrograms = response.data?.profit_and_loss_programs
        const accountTypes = [...response.data?.account_types]
        let coas: ChartOfAccountProfitAndLoss[] = []
        for (const item of pnlPrograms) {
          for (const iitem of item.account_type_profit_and_loss) {
            if (iitem.chart_of_account_profit_and_loss?.length > 0) {
              for (const iiitem of iitem.chart_of_account_profit_and_loss) {
                const test = coas.findIndex((el) => el.id === iiitem.id)
                if (test === -1) {
                  coas.push(iiitem)
                }
              }
            }
          }
        }

        for (const at of accountTypes) {
          at.chart_of_account_profit_and_loss = coas.filter((el) => el.account_type_id === at.id)
        }

        set({
          accountTypes: accountTypes,
        })
      }
    }

    set({profitAndLossLoading: false})
  },
  resetPnL: () => {
    set({
      profitAndLossLoading: false,
      profitAndLossModel: undefined,
      accountTypes: [],
      profitAndLossPrograms: [],
    })
  },
}))
