import { AxiosError } from "axios";
import { ApiFinance } from "../../../../../api/api";
import { GeneralInterface } from "../../../../../interfaces/general.interface";
import { TransactionPaymentResultInterface } from "../interfaces/transaction-payment-result.interface";
import { TransactionPaymentSingleResultInterface } from "../interfaces/transaction-payment-single-result.interface";

export interface GetAllTransactionPaymentParam {
  page?: number;
  size?: number;
}

export const GetAllTransactionPayment = async (params: GetAllTransactionPaymentParam): Promise<TransactionPaymentResultInterface> => {
  try {
    let response = await ApiFinance.get(
      '/transaction-payments',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: TransactionPaymentResultInterface = a.response?.data;
    return response;
  }
}

export const ShowTransactionPayment = async (id: string): Promise<TransactionPaymentSingleResultInterface> => {
  try {
    let response = await ApiFinance.get(
      '/transaction-payments/' + id,
    );
    return response.data as TransactionPaymentSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: TransactionPaymentSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitTransactionPayment = async (form: any): Promise<TransactionPaymentSingleResultInterface> => {
  try {
    let response = await ApiFinance.post(
      '/transaction-payments',
      form
    );
    return response.data as TransactionPaymentSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: TransactionPaymentSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateTransactionPayment = async (id: string, form: any): Promise<TransactionPaymentSingleResultInterface> => {
  try {
    let response = await ApiFinance.put(
      '/transaction-payments/' + id,
      form
    );
    return response.data as TransactionPaymentSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: TransactionPaymentSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteTransactionPayment = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiFinance.delete(
      '/transaction-payments/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}