import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useAuth} from '../../../auth'
import {SelectWithText} from '../../../forms/components/input.component'
import {useDashboardStore} from '../states/dashboard.state'
import {KTCard} from '../../../../../_metronic/helpers'
import {usePeriodeStore} from '../../finance/periodes/states/periode.state'
import {useEffect, useState} from 'react'
import {useDivisionStore} from '../../finance/divisions/states/division.state'
import {SelectData} from '../../../../interfaces/form-action-interfaces'
import moment from 'moment'
import {LoadingComponent} from '../../crm/properties-management/components/loading.component'
import {generateCurrencyFormat} from '../../../../helpers/generate-currency-format'

const DashboardScreen = () => {
  const {startDate, endDate, setStartDate, setEndDate} = useDashboardStore()

  const {getPeriodes, periodeSelectDatas, periodeItems} = usePeriodeStore()
  const {
    getDashboardDivisionIncome,
    dashboardDivisionIncomeLoading,
    dashboardDivisionIncome,
    getDashboardDivisionExpense,
    dashboardDivisionExpense,
    dashboardDivisionExpenseLoading,
  } = useDivisionStore()
  const [periode, setPeriode] = useState<SelectData>()
  const {currentUser} = useAuth()

  useEffect(() => {
    getPeriodes({page: 0, size: 10000})
  }, [])

  if (currentUser?.data?.company?.id === '116702c0-7602-460f-a082-110cdb24021d') {
    return (
      <>
        <iframe
          src={currentUser?.data?.iframe_url}
          frameBorder={0}
          width={'100%'}
          height={600}
          allowTransparency
          title='Dashboard Universa'
        />
      </>
    )
  }

  return (
    <>
      <KTCard className='mt-2'>
        <div className='card-body py-3'>
          <div className='row'>
            <SelectWithText
              title={'Periode'}
              name={'periode_id'}
              selectData={periodeSelectDatas}
              type={'select-with-text'}
              value={periode}
              disabled={false}
              id={'periode_id'}
              onChangeSelectWithText={(e) => {
                setPeriode(e)
                const periodeIndex = periodeItems.findIndex((item) => item.id === e.value)
                getDashboardDivisionIncome(
                  moment(periodeItems[periodeIndex]?.start_date).format('YYYY-MM-DD'),
                  moment(periodeItems[periodeIndex]?.finish_date).format('YYYY-MM-DD'),
                  periodeItems[periodeIndex]?.id,
                  'profit'
                )
                getDashboardDivisionExpense(
                  moment(periodeItems[periodeIndex]?.start_date).format('YYYY-MM-DD'),
                  moment(periodeItems[periodeIndex]?.finish_date).format('YYYY-MM-DD'),
                  periodeItems[periodeIndex]?.id,
                  'cost'
                )
              }}
              isClearable={true}
              size={6}
              onInputChange={(e) => {
                console.log(e)
              }}
            />
          </div>
        </div>
      </KTCard>
      <KTCard className='mt-2'>
        <div className='card-header border-1'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Income</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Realisasi dan Target</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {dashboardDivisionIncomeLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Department</th>
                  <th>Divisi</th>
                  <th>Realisasi</th>
                  <th>Target</th>
                  <th>Persentase</th>
                </tr>
              </thead>
              <tbody>
                {dashboardDivisionIncome?.data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.department_name}</td>
                      <td>{item.division_name}</td>
                      <td>{generateCurrencyFormat(item.realization)}</td>
                      <td>{generateCurrencyFormat(item.target)}</td>
                      <td>{item.completion}%</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </KTCard>
      <KTCard className='mt-2'>
        <div className='card-header border-1'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Expense</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Realisasi dan Target</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {dashboardDivisionExpenseLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Department</th>
                  <th>Divisi</th>
                  <th>Realisasi</th>
                  <th>Target</th>
                  <th>Persentase</th>
                </tr>
              </thead>
              <tbody>
                {dashboardDivisionExpense?.data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.department_name}</td>
                      <td>{item.division_name}</td>
                      <td>{generateCurrencyFormat(item.realization)}</td>
                      <td>{generateCurrencyFormat(item.target)}</td>
                      <td>{item.completion}%</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </KTCard>
      {/* <div className='row gy-5 g-xl-10'>
        <div className='col-12' style={{marginBottom: -20}}>
          <div className='row'>
            <div className='col-2'>
              <InputDatePicker
                id={'start_date'}
                title={'Start Date'}
                name={'start_date'}
                type={'date'}
                value={startDate}
                onChangeDate={(e) => {
                  setStartDate(e)
                }}
              />
            </div>
            <div className='col-2'>
              <InputDatePicker
                id={'end_date'}
                title={'End Date'}
                name={'end_date'}
                type={'date'}
                value={endDate}
                onChangeDate={(e) => {
                  setEndDate(e)
                }}
              />
            </div>
          </div>
        </div>
        <TotalDonationCardComponent />
      </div> */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      {currentUser?.data?.role?.slug && (
        <>
          {currentUser?.data?.role?.slug === 'admin' && <DashboardScreen />}
          {currentUser?.data?.role?.slug === 'superadmin' && <DashboardScreen />}
        </>
      )}
    </>
  )
}

export default DashboardWrapper
