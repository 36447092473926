import moment from 'moment'
import {toast} from 'react-toastify'
import {create} from 'zustand'
import {FormInput, SelectData} from '../../../../../interfaces/form-action-interfaces'
import {ProgramData} from '../../../program/program/interfaces/program-result.interface'
import {ShowProgram} from '../../../program/program/providers/program.provider'
import {ChartOfAccount} from '../../affected-accounts/interfaces/affected-account-result.interface'
import {GetAllChartOfAccount} from '../../chart-of-accounts/providers/chart-of-account.provider'
import {
  TransactionPaymentData,
  TransactionPaymentDetailData,
  TransactionPaymentResultInterface,
} from '../interfaces/transaction-payment-result.interface'
import {
  DeleteTransactionPayment,
  GetAllTransactionPayment,
  GetAllTransactionPaymentParam,
  SubmitTransactionPayment,
  UpdateTransactionPayment,
} from '../providers/transaction-payment.provider'

interface TransactionPaymentState {
  transactionPaymentLoading: boolean
  transactionPaymentItems: TransactionPaymentData[]
  transactionPaymentModel?: TransactionPaymentResultInterface
  getTransactionPayments: (params: GetAllTransactionPaymentParam) => void
  onDelete: (id: string, params: GetAllTransactionPaymentParam) => void
}

export const useTransactionPaymentStore = create<TransactionPaymentState>((set, get) => ({
  transactionPaymentLoading: false,
  transactionPaymentItems: [],
  transactionPaymentSelectDatas: [],
  transactionPaymentModel: undefined,
  getTransactionPayments: async (params: GetAllTransactionPaymentParam) => {
    set({transactionPaymentLoading: true})
    const response = await GetAllTransactionPayment(params)
    set({transactionPaymentModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        set({
          transactionPaymentItems: response.data.items,
        })
      } else {
        set({
          transactionPaymentItems: [],
        })
      }
    } else {
      set({
        transactionPaymentItems: [],
      })
    }
    set({transactionPaymentLoading: false})
  },
  onDelete: async (id: string, params: GetAllTransactionPaymentParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === false) return
    set({transactionPaymentLoading: true})
    const response = await DeleteTransactionPayment(id)
    if (response.status) {
      toast.success(response.message)
    } else {
      toast.error(response.message)
    }
    get().getTransactionPayments(params)
    set({transactionPaymentLoading: false})
  },
}))

interface TransactionPaymentFormState {
  selectedProgram: ProgramData
  setSelectedProgram: (program: ProgramData) => void
  transactionPaymentFormReset: () => void
  transactionPaymentDetails: TransactionPaymentDetailData[]
  addTransactionPaymentDetails?: () => void
  removeTransactionPaymentDetails?: (index: number) => void
  transactionPaymentDetailForms: FormInput[]
  setTransactionPaymentDetailForms: (index: number, value: any) => void
  generateTransactionPaymentDetailForms?: () => void
  selectedCoa?: ChartOfAccount
  selectedPosition?: string
  selectedAmount?: number
  setSelectedCoa?: (coa: ChartOfAccount) => void
  setSelectedPosition?: (pos: string) => void
  setSelectedAmount?: (amount: number) => void
  postedDate?: Date
  setPostedDate?: (date: Date) => void
  remarks?: string
  setRemarks?: (remarks: string) => void
  document?: string
  setDocument?: (document: string) => void
  onSubmit: (creditCoa: string) => void
  selectedTransactionPayment?: TransactionPaymentData
  setSelectedTransactionPayment?: (data: TransactionPaymentData) => void
  getSelectedProgramByProgramId?: (programId: string) => void
}

export const useTransactionPaymentFormStore = create<TransactionPaymentFormState>((set, get) => ({
  selectedProgram: undefined,
  transactionPaymentDetails: [],
  transactionPaymentDetailForms: [],
  selectedTransactionPayment: undefined,
  setSelectedTransactionPayment: (data: TransactionPaymentData) => {
    set({transactionPaymentDetails: data?.transaction_payment_details ?? []})
    set({selectedTransactionPayment: data})
  },
  setSelectedProgram: (program: ProgramData) => {
    set({selectedProgram: program})
  },
  getSelectedProgramByProgramId: async (programId: string) => {
    const response = await ShowProgram(programId)
    if (response.status) {
      get().setSelectedProgram(response.data)
    } else {
      get().setSelectedProgram(undefined)
    }
  },
  transactionPaymentFormReset: () => {
    set({
      selectedProgram: undefined,
      postedDate: undefined,
      remarks: undefined,
      selectedAmount: undefined,
      selectedCoa: undefined,
      selectedPosition: undefined,
    })
  },
  addTransactionPaymentDetails: () => {
    const datas = [...get().transactionPaymentDetails]
    let form: TransactionPaymentDetailData = {
      chart_of_account: {
        id: get().selectedCoa?.id,
        coa_code: get().selectedCoa?.coa_code,
        name: get().selectedCoa?.name,
      },
      chart_of_account_id: get().selectedCoa?.id,
      position: 'Debit',
      // position: get().selectedPosition,
      amount: get().selectedAmount,
    }
    datas.push(form)
    set({transactionPaymentDetails: datas})
  },
  removeTransactionPaymentDetails: (index: number) => {
    const datas = [...get().transactionPaymentDetails]
    datas.splice(index, 1)
    set({transactionPaymentDetails: datas})
  },
  setTransactionPaymentDetailForms: (index: number, value: any) => {
    const forms = [...get().transactionPaymentDetailForms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({transactionPaymentDetailForms: forms})
  },
  generateTransactionPaymentDetailForms: async () => {
    const response = await GetAllChartOfAccount({page: 0, size: 10000})
    const selectDatas: SelectData[] = []

    for (const item of response?.data?.items) {
      selectDatas.push({
        label: `${item.coa_code} - ${item.name}`,
        value: item.id,
      })
    }

    let forms: FormInput[] = []
    forms = [
      {
        id: 'chart_of_account_id',
        title: 'COA',
        placeholder: 'COA...',
        type: 'select-with-text',
        name: 'chart_of_account_id',
        selectData: selectDatas,
        disabled: false,
        required: true,
      },
      {
        id: 'position',
        title: 'Position',
        placeholder: 'Position...',
        type: 'select',
        name: 'position',
        value: '',
        selectData: [
          {
            value: 'Debit',
            label: 'Debit',
          },
          {
            value: 'Credit',
            label: 'Credit',
          },
        ],
        disabled: false,
        required: true,
      },
      {
        id: 'amount',
        title: 'Nilai',
        placeholder: 'Nilai...',
        type: 'currency',
        name: 'amount',
        disabled: false,
        required: true,
      },
    ]
    set({transactionPaymentDetailForms: forms})
  },
  selectedCoa: undefined,
  selectedPosition: 'Debit',
  selectedAmount: undefined,
  setSelectedCoa: (coa: ChartOfAccount) => {
    set({selectedCoa: coa})
  },
  setSelectedPosition: (pos: string) => {
    set({selectedPosition: pos})
  },
  setSelectedAmount: (amount: number) => {
    set({selectedAmount: amount})
  },
  postedDate: undefined,
  setPostedDate: (date: Date) => {
    set({postedDate: date})
  },
  remarks: undefined,
  setRemarks: (remarks: string) => {
    set({remarks: remarks})
  },
  onSubmit: async (creditCoa: string) => {
    let accounts: TransactionPaymentDetailData[] = []
    let creditAmount = 0

    //for credit amounts
    for (const item of get().transactionPaymentDetails) {
      if (item.chart_of_account_id !== creditCoa) {
        if (item.position === 'Credit') {
          creditAmount += item.amount
        }
        if (item.position === 'Debit') {
          creditAmount -= item.amount
        }
      }
    }
    accounts.push({
      chart_of_account: {
        id: creditCoa,
      },
      chart_of_account_id: creditCoa,
      position: 'Credit',
      amount: Math.abs(creditAmount),
    })

    // add debit accounts
    for (const item of get().transactionPaymentDetails) {
      if (item.chart_of_account_id !== creditCoa) {
        accounts.push(item)
      }
    }

    let form = {
      program_id: get().selectedProgram?.id ?? '',
      remarks: get().remarks ?? '',
      posted_date: moment(get().postedDate).format('YYYY-MM-DD') ?? '',
      transaction_payment_details: accounts ?? [],
      document: get().document,
    }

    if (get().selectedTransactionPayment === undefined) {
      const res = await SubmitTransactionPayment(form)
      if (res.status) {
        toast.success(res.message)
        // set({ submitDone: true, formLoading: false, field: undefined });
        return
      } else {
        toast.error(res.message)
        // set({ submitDone: false, formLoading: false });
        return
      }
    } else {
      const tr = get().selectedTransactionPayment
      const res = await UpdateTransactionPayment(tr?.id, form)
      if (res.status) {
        toast.success(res.message)
        // set({ submitDone: true, formLoading: false, field: undefined });
        return
      } else {
        toast.error(res.message)
        // set({ submitDone: false, formLoading: false });
        return
      }
    }
  },
  document: undefined,
  setDocument: (doc: string) => {
    set({document: doc})
  },
}))
