import { AxiosError } from "axios";
import { ApiCrm } from "../../../../../api/api";
import { MessageResultInterface } from "../interfaces/message-result.interface";

export interface GetAllMessageParam {
  page?: number,
  size?: number,
  room_id?: string,
}

export const GetAllMessage = async (params: GetAllMessageParam): Promise<MessageResultInterface> => {
  try {
    let response = await ApiCrm.get(
      '/messages',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: MessageResultInterface = a.response?.data;
    return response;
  }
}