import {AxiosError} from 'axios'
import {Api} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {UserResultInterface, UserData} from '../interfaces/user-result.interface'
import {UserSingleResultInterface} from '../interfaces/user-single-result.interface'

export interface GetAllUserParam {
  page?: number
  size?: number
  search?: string
  name?: string
  phone?: string
  email?: string
  filters?: string
}

export const GetAllUser = async (params: GetAllUserParam): Promise<UserResultInterface> => {
  try {
    let response = await Api.get('/users-company-v2', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: UserResultInterface = a.response?.data
    return response
  }
}

export const ShowUser = async (id: string): Promise<UserSingleResultInterface> => {
  try {
    let response = await Api.get('/users/' + id)
    return response.data as UserSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: UserSingleResultInterface = a.response?.data
    return response
  }
}

export const ShowUserByReference = async (reference: string): Promise<UserResultInterface> => {
  try {
    let response = await Api.get('/users-by-reference', {
      params: {
        reference_table: reference,
      },
    })
    return response.data as UserResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: UserResultInterface = a.response?.data
    return response
  }
}

export const SubmitUser = async (form: UserData): Promise<UserSingleResultInterface> => {
  try {
    let response = await Api.post('/users', form)
    return response.data as UserSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: UserSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateUser = async (
  id: string,
  form: UserData
): Promise<UserSingleResultInterface> => {
  try {
    let response = await Api.put('/users/' + id, form)
    return response.data as UserSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: UserSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteUser = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await Api.delete('/users/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const BulkSortingUser = async (
  ids: string[],
  sorts: number[]
): Promise<GeneralInterface> => {
  try {
    let response = await Api.put('/users-sorting', {
      ids: ids,
      sorts: sorts,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
