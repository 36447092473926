/* eslint-disable jsx-a11y/anchor-is-valid */
import {createRef, FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {
  KTSVG,
  MessageModel,
} from '../../helpers'
import { RoomData } from '../../../app/modules/apps/crm/whatsapp-channel-management/interfaces/room-result.interface';
import { useRoomMessageStore } from '../../../app/modules/apps/crm/whatsapp-channel-management/states/room-message.state';
import { useWhatsappChannelStore } from '../../../app/modules/apps/crm/whatsapp-channel-management/states/whatsapp-channel.state';
import { dateTimeago } from '../../helpers/datetime-formatter';
import { LoadingComponent } from '../../../app/modules/apps/crm/properties-management/components/loading.component';
import MessageBoxComponent from './message-box.component';
import { UploadContextProvider } from '../../../app/modules/uploads/states/upload-context';
import { generateImageUrl } from '../../helpers/generate-image-url';
import { Message, WhatsappChatResult } from '../../../app/modules/apps/crm/whatsapp-channel-management/interfaces/wa-chat-result.interface';

type Props = {
  isDrawer?: boolean;
  activeRoom?: RoomData;
  status?: string;
}

const ChatInner: FC<Props> = ({isDrawer = false, activeRoom, status}) => {
  const [messages, setMessages] = useState<MessageModel[]>([]);
  // const messagesEndRef = createRef<HTMLDivElement>()
  const messagesEndRef = useRef<HTMLDivElement>()
  const { whatsappChats } = useWhatsappChannelStore(); 
  const { getMessages, messageItems, messageLoading } = useRoomMessageStore();
  const [viewFile, setViewFile] = useState<string>(undefined);

  useEffect(() => {
    if (activeRoom !== undefined) {
      getMessages(activeRoom?.id);
    }
  }, [activeRoom]);

  useEffect(() => {
    if (messages.length > 0) {
      setTimeout(() => {
        messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 1000)
    }
  }, [messages])

  useEffect(() => {
    if (messageItems.length > 0) {
      const msgs: MessageModel[] = [];
      for (const items of messageItems) {
        const newMessage: MessageModel = transformMessage({
          message: items.sender?.message,
          createdAt: items.created_at,
          pushName: items.sender?.pushName,
          userId: items.sender?.key?.id,
          fromMe: items.sender?.key?.fromMe
        });
        msgs.push(newMessage);
      }
      setMessages(msgs);
    }
  }, [messageItems])

  const transformMessage = ({ message, userId, fromMe, createdAt, pushName }: {
    message: Message,
    userId: string,
    fromMe: boolean,
    createdAt: Date,
    pushName: string
  }): MessageModel => {
    let newMessage: MessageModel = undefined;
    if (message?.extendedTextMessage !== undefined && message?.extendedTextMessage?.text !== '') {
      newMessage = {
        user: userId,
        type: fromMe ? 'out' : 'in',
        text: message?.extendedTextMessage?.text ?? '',
        time: dateTimeago(createdAt),
        pushName: pushName,
        file: undefined,
        mimeType: undefined,
      }
      console.log("dsadsa-extendedTextMessage");
    } else if (message.hasOwnProperty('conversation') && message?.conversation !== "") {
      newMessage = {
        user: userId,
        type: fromMe ? 'out' : 'in',
        text: message?.conversation,
        time: dateTimeago(createdAt),
        pushName: pushName,
        file: message?.imageMessage?.fileUrl,
        mimeType: undefined,
      }
      console.log("dsadsa-conversation");
    } else if (
      message.hasOwnProperty('imageMessage') && 
      message?.imageMessage?.hasOwnProperty('url') &&
      message?.imageMessage?.url !== ''
    ) {
      newMessage = {
        user: userId,
        type: fromMe ? 'out' : 'in',
        text: message?.imageMessage?.caption ?? "",
        time: dateTimeago(createdAt),
        pushName: pushName,
        file: message?.imageMessage?.fileUrl,
        mimeType: message?.imageMessage?.mimetype,
      }
      console.log("dsadsa-imageMessage");
    } else if (
      message.hasOwnProperty('videoMessage') && 
      message?.videoMessage?.hasOwnProperty('url') &&
      message?.videoMessage?.url !== ''
    ) {
      newMessage = {
        user: userId,
        type: fromMe ? 'out' : 'in',
        text: message?.videoMessage?.caption ?? "",
        time: dateTimeago(createdAt),
        pushName: pushName,
        file: message?.videoMessage?.fileUrl,
        mimeType: message?.videoMessage?.mimetype,
      }
      console.log("dsadsa-videoMessage");
    } else if (
      message.hasOwnProperty('documentMessage') && 
      message?.documentMessage?.hasOwnProperty('url') &&
      message?.documentMessage?.url !== ''
    ) {
      newMessage = {
        user: userId,
        type: fromMe ? 'out' : 'in',
        text: message?.documentMessage?.caption ?? '',
        time: dateTimeago(createdAt),
        pushName: pushName,
        file: message?.documentMessage?.fileUrl,
        mimeType: message?.documentMessage?.mimetype,
      }
      console.log("dsadsa-documentMessage");
    } else if (
      message.hasOwnProperty('viewOnceMessage') && 
      message?.viewOnceMessage?.message?.hasOwnProperty('buttonsMessage') && 
      message?.viewOnceMessage?.message?.buttonsMessage?.contentText !== ''
    ) {
      let buttonMimeType = "";
      if (message?.viewOnceMessage?.message?.buttonsMessage?.imageMessage?.url !== "") {
        buttonMimeType = message?.viewOnceMessage?.message?.buttonsMessage?.imageMessage?.mimetype;
      } else if (message?.viewOnceMessage?.message?.buttonsMessage?.videoMessage?.url !== "") {
        buttonMimeType = message?.viewOnceMessage?.message?.buttonsMessage?.videoMessage?.mimetype;
      } else if (message?.viewOnceMessage?.message?.buttonsMessage?.documentMessage?.url !== "") {
        buttonMimeType = message?.viewOnceMessage?.message?.buttonsMessage?.documentMessage?.mimetype;
      }

      newMessage = {
        user: userId,
        type: fromMe ? 'out' : 'in',
        text: message?.viewOnceMessage?.message?.buttonsMessage?.contentText,
        time: dateTimeago(createdAt),
        pushName: pushName,
        file: message?.viewOnceMessage?.fileUrl,
        mimeType: buttonMimeType,
        buttons: message?.viewOnceMessage?.message?.buttonsMessage?.buttons?.map((item) => item.buttonText?.displayText),
        footer: message?.viewOnceMessage?.message?.buttonsMessage?.footerText
      }
      console.log("dsadsa-videoMessage");
    } else if (
      message.hasOwnProperty('viewOnceMessage') && 
      message?.viewOnceMessage?.message?.hasOwnProperty('listMessage') && 
      message?.viewOnceMessage?.message?.listMessage?.title !== ''
    ) {
      newMessage = {
        user: userId,
        type: fromMe ? 'out' : 'in',
        text: message?.viewOnceMessage?.message?.listMessage?.description,
        time: dateTimeago(createdAt),
        pushName: pushName,
        file: '',
        mimeType: '',
        buttons: [],
        lists: message?.viewOnceMessage?.message?.listMessage?.sections[0]?.rows?.map((el) => el.title),
        footer: message?.viewOnceMessage?.message?.listMessage?.footerText,
        title: message?.viewOnceMessage?.message?.listMessage?.title
      }
      console.log("dsadsa-viewOnceMessage");
    } else if (
      message.hasOwnProperty('buttonsResponseMessage') && 
      message?.buttonsResponseMessage?.selectedDisplayText !== ''
    ) {
      newMessage = {
        user: userId,
        type: fromMe ? 'out' : 'in',
        text: message?.buttonsResponseMessage?.selectedDisplayText,
        time: dateTimeago(createdAt),
        pushName: pushName,
        file: '',
        mimeType: ''
      }
      console.log("dsadsa-buttonsResponseMessage");
    } else if (
      message.hasOwnProperty('listResponseMessage') && 
      message?.listResponseMessage?.title !== ''
    ) {
      newMessage = {
        user: userId,
        type: fromMe ? 'out' : 'in',
        text: message?.listResponseMessage?.title,
        time: dateTimeago(createdAt),
        pushName: pushName,
        file: '',
        mimeType: ''
      }
      console.log("dsadsa-listResponseMessage");
    } else {
      newMessage = {
        user: userId,
        type: fromMe ? 'out' : 'in',
        text: 'Unknown message type',
        time: dateTimeago(createdAt),
        pushName: pushName,
        file: undefined,
        mimeType: undefined
      }
      console.log("dsadsa-unknown");
    }
    return newMessage;
  }

  useEffect(() => {
    if (whatsappChats.length > 0) {
      if (activeRoom) {
        if (
          activeRoom?.sender?.number === whatsappChats[whatsappChats.length - 1].key?.remoteJid.split('@')[0]
        ) {

          const newMessage: MessageModel = transformMessage({
            message: whatsappChats[whatsappChats.length - 1]?.message,
            createdAt: new Date(),
            pushName: whatsappChats[whatsappChats.length - 1]?.pushName,
            userId: whatsappChats[whatsappChats.length - 1]?.key?.id,
            fromMe: whatsappChats[whatsappChats.length - 1]?.key?.fromMe
          });
          const test = [...messages];
          test.push(newMessage);
          setMessages(test);

          // const from = whatsappChats[whatsappChats.length - 1].key.remoteJid.split('@')[0];
          // setDestination(from);
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [whatsappChats]);

  const generateThumbnail = (message: MessageModel) => {
    if (message?.mimeType?.includes("image")) {
      return <img
        src={generateImageUrl(message.file)}
        alt={message.file}
        style={{
          borderRadius: 10,
          width: 150,
          height: 150,
          objectFit: 'cover'
        }}
        className={'mb-2'}
        onClick={e => {
          setViewFile(generateImageUrl(message.file));
        }}
      />;
    } else if (message?.mimeType?.includes("video")) {
      return <video width="320" height="240" controls>
        <source src={generateImageUrl(message.file)} type={message.mimeType}/>
      </video> 
    } else {
      if (message.file === undefined || message.file === '') {
        return <></>;
      }

      return (
        <a 
          className='btn btn-sm btn-success mb-2' 
          href={generateImageUrl(message.file)}
          rel="noreferrer"
          target={'_blank'}>
            View File
        </a>
      )
    }
  }

  return (
    <>
      {
        messageLoading 
        ? <LoadingComponent/>
        : <div
          className='card-body'
          id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
          style={{
            height: status === "open" ? 650 : 650,
          }}>
          <div
            className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
            data-kt-element='messages'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies={
              isDrawer
                ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
            }
            data-kt-scroll-wrappers={
              isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
            }
            data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
            style={{
              height: status === "open" ? 400 : 600,
            }}>
            {messages.map((message, index) => {
              // const userInfo = userInfos[message.user]
              const state = message.type === 'in' ? 'info' : 'primary'
              const templateAttr = {}
              if (message.template) {
                Object.defineProperty(templateAttr, 'data-kt-element', {
                  value: `template-${message.type}`,
                })
              }
              const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                message.type === 'in' ? 'start' : 'end'
              } mb-10`

              if (message.file === "" && message.mimeType === "" && message.text === "") {
                return <div key={`message${index}`}></div>
              }

              return (
                <div
                  key={`message${index}`}
                  className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
                  {...templateAttr}>
                  <div
                    className={clsx(
                      'd-flex flex-column align-items',
                      `align-items-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                  >
                    <div className='d-flex align-items-center mb-2'>
                      {message.type === 'in' ? (
                        <>
                          <div className='symbol symbol-35px symbol-circle'>
                            <span className='symbol-label bg-light-danger text-danger fs-6 fw-bold'>
                              {message?.pushName[0]?.toUpperCase() ?? 'U'}
                            </span>
                          </div>
                          <div className='ms-3'>
                            <a
                              href='#'
                              className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'
                            >
                              {message.pushName}
                            </a>
                            <span className='text-muted fs-7 mb-1'>{message.time}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='me-3'>
                            <span className='text-muted fs-7 mb-1'>{message.time}</span>
                            <a
                              href='#'
                              className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'
                            >
                              You
                            </a>
                          </div>
                          <div className='symbol symbol-35px symbol-circle'>
                            <span className='symbol-label bg-light-danger text-danger fs-6 fw-bold'>
                              {'Y'}
                            </span>
                          </div>
                        </>
                      )}
                    </div>

                    {
                      message.file !== "" && generateThumbnail(message)
                    }

                    {
                      message.text !== "" &&
                      <div
                        className={clsx(
                          'p-5 rounded',
                          `bg-light-${state}`,
                          'text-dark fw-semibold mw-lg-400px',
                          `text-${message.type === 'in' ? 'start' : 'end'}`
                        )}
                        data-kt-element='message-text'>
                        {
                          message.title !== undefined && message.title !== "" 
                          ? <span className="text-muted fs-7 mt-10 mb-10">{message.title}</span>
                          : <></>
                        }
                        <div style={{ marginBottom: message.footer !== undefined && message.footer !== "" ? 25 : 0 }}dangerouslySetInnerHTML={{__html: message.text }}></div>
                        {
                          message.footer !== undefined && message.footer !== ""
                          ? <span className="text-muted fs-7" style={{ marginTop: 10 }}>{message.footer}</span>
                          : <></>
                        }
                      </div>
                    }

                    {
                      message.buttons !== undefined && message.buttons.length > 0
                      ?
                      <>
                        <span className='badge badge-success mt-2'>Buttons</span>
                        <div className='d-flex'>
                          {
                            message.buttons?.map((item, index) => (
                              <button key={index} className='btn btn-sm btn-primary ms-2 mt-2'>
                                {item}
                              </button>
                            ))
                          }
                        </div>
                      </>
                      : <></>
                    }

                    {
                      message.lists !== undefined && message.lists.length > 0
                      ?
                      <>
                        <span className='badge badge-success mt-2'>Lists</span>
                        <div className='d-flex'>
                          {
                            message.lists?.map((item, index) => (
                              <button key={index} className='btn btn-sm btn-primary ms-2 mt-2'>
                                {item}
                              </button>
                            ))
                          }
                        </div>
                      </>
                      : <></>
                    }
                    
                  </div>
                </div>
              )
            })}
            <div className="zxzxc" ref={messagesEndRef} />
          </div>
          
          {
            activeRoom?.status === "open"
            ?
            <UploadContextProvider>
              <MessageBoxComponent
                isDrawer={isDrawer}
                activeRoom={activeRoom}
                messages={messages}
                setMessages={setMessages}
                messagesEndRef={messagesEndRef}
              />
            </UploadContextProvider>
            : <></>
          }
          
        </div>
      }
      {
        viewFile !== undefined && 
        <ImageViewerModal
          viewFile={viewFile}
          setViewFile={setViewFile}
        />
      }
    </>
  )
}

interface ImageViewerModalProps {
  viewFile: string;
  setViewFile: React.Dispatch<React.SetStateAction<string>>
}

const ImageViewerModal: React.FC<ImageViewerModalProps> = ({
  viewFile,
  setViewFile
}) => {

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, []);

  return (
    <>
      <div 
        className="modal fade show d-block"
        id="kt_modal_properties"
        role='dialog'
        tabIndex={-1}
        aria-modal='true'>
        <div className="modal-dialog modal-dialog-centered mw-800px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bold">
                {'Image Viewer'}
              </h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setViewFile(undefined)}
                style={{cursor: 'pointer'}}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <img
                  src={viewFile}
                  alt={viewFile}
                  style={{
                    maxWidth: 500,
                    maxHeight: 500,
                    objectFit: 'contain',
                    textAlign: 'center'
                  }}
                />
              </div>         
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  );
}

export {ChatInner}
