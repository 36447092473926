import {AxiosError} from 'axios'
import {ApiFinance} from '../../../../../api/api'
import {ProfitAndLossResultInterface} from '../interfaces/profit-and-loss-result.interface'
import {SelectData} from '../../../../../interfaces/form-action-interfaces'

export interface GetAllProfitAndLossParam {
  start_date?: string
  end_date?: string
  program_ids?: SelectData[]
}

export const GetAllProfitAndLoss = async (
  params: GetAllProfitAndLossParam
): Promise<ProfitAndLossResultInterface> => {
  try {
    let programIds: string[] = []
    for (const program of params.program_ids) {
      programIds.push(program.value)
    }

    let response = await ApiFinance.get('/profit-and-loss', {
      params: {
        start_date: params.start_date,
        end_date: params.end_date,
        program_ids: programIds?.join(','),
      },
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ProfitAndLossResultInterface = a.response?.data
    return response
  }
}
