/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {MenuItem} from '../../header/header-menus/MenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/apps/finance'
        title='Finance'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com015.svg'
      >
        <SidebarMenuItem
          to='/apps/finance/receiving-management/receivings'
          title='Receive'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/finance/payment-management/payments'
          title='Payment'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/finance/transfer-bank-management/transfer-bank'
          title='Transfer Bank'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/finance/distributing-management/distributing'
          title='Distribution'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/report'
        title='Report'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/apps/report/general-ledger-management/general-ledger'
          title='General Ledger'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/report/general-ledger-management/trial-general-ledger'
          title='Trial Balance'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/report/profit-and-loss-management/profit-and-loss'
          title='Activity Report'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/report/balance-sheet-management/balance-sheet'
          title='Balance Sheet'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/report/laporan-perubahan-aset-management/laporan-perubahan-aset'
          title='Laporan Perubahan Aset'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/donation'
        title='Donation'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com017.svg'
      >
        <SidebarMenuItem
          to='/apps/donation/donation-management/donation'
          title='Donation Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/program'
        title='Program'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/apps/program/program-management/programs'
          title='Program Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>Master Data</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/master/finance'
        title='Finance'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com015.svg'
      >
        <SidebarMenuItem
          to='/master/finance/account-types-management/account-types'
          title='Account Type'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/finance/chart-of-accounts-management/chart-of-accounts'
          title='Chart of Account'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/finance/periode-management/periodes'
          title='Periode'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/finance/department-management/departments'
          title='Department'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/finance/division-management/divisions'
          title='Divisi'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/finance/donation-type-management/donation-type'
          title='Setup Donation Type'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/finance/bank-mutations-management/bank-mutations'
          title='Bank Mutasi'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>{`User & Role`}</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/communication/com019.svg'
        title='User Management'
        fontIcon='bi-layers'
      />
      {currentUser?.data?.company?.id === '21db0100-5e59-4a79-90b2-eeb6a7a7097e' ? (
        <SidebarMenuItem
          to='/apps/role-management/roles'
          icon='/media/icons/duotune/communication/com020.svg'
          title='Role Management'
          fontIcon='bi-layers'
        />
      ) : (
        <></>
      )}
      <SidebarMenuItem
        to='/apps/company-management/company'
        icon='/media/icons/duotune/communication/com021.svg'
        title='Company'
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
