import {FormInput} from '../../../interfaces/form-action-interfaces'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import {NumericFormat} from 'react-number-format'
import {WithContext as ReactTags} from 'react-tag-input'
import TimePicker from 'react-time-picker'

const Input: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  hasInputGroup,
  inputGroupAction,
  inputGroupTitle,
  hidden = false,
  required = true,
  size = 12,
}) => {
  if (hidden) {
    return (
      <input
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        type={type}
        name={name}
        disabled={disabled}
        hidden={hidden}
        className={'form-control mb-3 mb-lg-0'}
        required={required}
      />
    )
  }
  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>
      <input
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        type={type}
        name={name}
        disabled={disabled}
        hidden={hidden}
        className={'form-control mb-3 mb-lg-0'}
        required={required}
      />
    </div>
  )
}

const InputCurrency: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  onValueChange,
  hidden = false,
  required = true,
  size = 12,
}) => {
  return (
    <div className={`fv-row mb-7 col-${size}`}>
      {title?.length > 0 ? (
        <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
          {title}
        </label>
      ) : null}

      <NumericFormat
        onChange={onChange}
        onValueChange={onValueChange}
        placeholder={placeholder}
        value={value}
        name={name}
        disabled={disabled}
        hidden={hidden}
        className={'form-control mb-3 mb-lg-0'}
        required={required}
        allowLeadingZeros
        thousandSeparator={'.'}
        decimalSeparator={','}
      />
    </div>
  )
}

const InputTextArea: React.FC<FormInput> = ({
  title,
  placeholder,
  onChangeTextArea,
  type,
  value,
  name,
  disabled = false,
  hasInputGroup,
  inputGroupAction,
  inputGroupTitle,
  hidden = false,
  required = true,
  size = 12,
}) => {
  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>
      <textarea
        onChange={onChangeTextArea}
        placeholder={placeholder}
        value={value}
        name={name}
        disabled={disabled}
        hidden={hidden}
        className={'form-control mb-3 mb-lg-0'}
        required={required}
      >
        {value}
      </textarea>
    </div>
  )
}

const InputDatePicker: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  onChangeDate,
  disabled = false,
  required = true,
  hidden = false,
  size = 12,
}) => {
  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>
      <DatePicker
        dateFormat='yyyy-MM-dd'
        name={name}
        className={'form-control'}
        selected={value}
        disabled={disabled}
        showYearDropdown={true}
        onChange={onChangeDate}
      />
    </div>
  )
}

const InputDateTimePicker: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  onChangeDate,
  disabled = false,
  required = true,
  hidden = false,
  size = 12,
}) => {
  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>
      <DatePicker
        dateFormat='Pp'
        name={name}
        className={'form-control'}
        selected={value}
        disabled={disabled}
        showTimeSelect={true}
        timeIntervals={1}
        onChange={onChangeDate}
      />
    </div>
  )
}

const SelectInput: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  hidden,
  value,
  name,
  selectData,
  onChangeSelect,
  disabled = false,
  required = true,
  size = 12,
}) => {
  if (hidden) {
    return (
      <select
        className='form-select'
        aria-label='Select example'
        name={name}
        disabled={disabled}
        onChange={onChangeSelect}
        hidden={hidden}
        value={value}
      >
        <option value={''}>{placeholder}</option>
        {selectData?.map((v, k) => (
          <option key={k} value={v.value} selected={value === v.value ? true : false}>
            {v.label}
          </option>
        ))}
      </select>
    )
  }

  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>
      <select
        className='form-select'
        aria-label='Select example'
        name={name}
        disabled={disabled}
        onChange={onChangeSelect}
      >
        <option value={''}>{placeholder}</option>
        {selectData?.map((v, k) => (
          <option key={k} value={v.value} selected={value === v.value ? true : false}>
            {v.label}
          </option>
        ))}
      </select>
    </div>
  )
}

const CheckboxInput: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  hidden = false,
  disabled = false,
  required = true,
  size = 12,
}) => {
  if (hidden === true) {
    return (
      <input
        className='form-check-input'
        type={type}
        checked={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
        hidden={hidden}
      />
    )
  }
  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5  ${required ? 'required' : ''}`}>
        {title}
      </label>
      <div className='form-check form-check-custom'>
        <input
          className='form-check-input'
          type={type}
          checked={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
          hidden={hidden}
        />
        <label className='form-check-label ml-5' htmlFor='flexCheckDefault'>
          {placeholder}
        </label>
      </div>
    </div>
  )
}

const SelectWithText: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  onChangeSelectWithText,
  selectData,
  onInputChange,
  isMulti = false,
  required = false,
  size = 12,
}) => {
  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>
      <Select
        isMulti={isMulti}
        options={selectData}
        placeholder={placeholder}
        onChange={onChangeSelectWithText}
        name={name}
        value={value}
        isDisabled={disabled}
        onInputChange={onInputChange}
        menuPortalTarget={document.body}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        isClearable={true}
      />
    </div>
  )
}

const MultipleInputText: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  values,
  onChangeValues,
  disabled = false,
  hasInputGroup,
  inputGroupAction,
  inputGroupTitle,
  hidden = false,
  required = true,
  size = 12,
}) => {
  return (
    <>
      <div className='fv-row mb-7'>
        <label className={`d-block fw-semibold fs-6 mb-5 required`}>{title}</label>
        {values?.map((item, index) => {
          return (
            <div key={index} className='input-group mb-5'>
              <input
                type='text'
                className='form-control'
                placeholder={placeholder}
                aria-label={placeholder}
                aria-describedby='basic-addon2'
                value={item}
                onChange={(e) => {
                  const items = [...values]
                  items[index] = e.target.value
                  onChangeValues(items)
                }}
              />
              <span
                className='input-group-text'
                id='basic-addon2'
                onClick={(e) => {
                  const items = [...values]
                  items.splice(index, 1)
                  onChangeValues(items)
                }}
              >
                <i className='fas fa-trash fs-4 text-danger' />
              </span>
            </div>
          )
        })}
        <button
          className='btn btn-primary'
          style={{
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault()
            const items = [...values]
            items.push('')
            console.log(items)
            onChangeValues(items)
          }}
        >
          {`+ Add ${title}`}
        </button>
      </div>
    </>
  )
}

const TagInput: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  tags,
  suggestions,
  handleAddition,
  handleDelete,
  handleDrag,
  size = 12,
  required = true,
}) => {
  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  }
  const delimiters = [...KeyCodes.enter, KeyCodes.comma]

  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>
      <ReactTags
        classNames={{
          tagInputField: 'form-control mb-3 mb-lg-0 mt-5',
          tag: 'btn btn-success me-5',
          remove: 'badge badge-circle badge-warning ms-2',
        }}
        name={name}
        inline={true}
        tags={tags}
        suggestions={suggestions}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        delimiters={delimiters}
        readOnly={disabled}
      />
    </div>
  )
}

const InputTimePicker: React.FC<FormInput> = ({
  title,
  placeholder,
  type,
  value,
  name,
  onChangeTime,
  disabled = false,
  required = true,
  hidden = false,
  size = 12,
}) => {
  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>
      <TimePicker
        onChange={onChangeTime}
        name={name}
        value={value}
        disabled={disabled}
        format={'hh:mm a'}
        locale={'sv-sv'}
      />
    </div>
  )
}

export {
  Input,
  SelectInput,
  CheckboxInput,
  InputTextArea,
  InputDatePicker,
  SelectWithText,
  InputCurrency,
  InputDateTimePicker,
  MultipleInputText,
  TagInput,
  InputTimePicker,
}
