import moment from 'moment'

export const dateFinanceFormatter = (unix: number) => {
  return moment(unix).format('DD/MM/YYYY')
}

export const dateQueryFormatter = (date: Date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const dateTimeISOFormatter = (date: Date) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

export const dateTimeFormatter = (unix: number) => {
  return moment(unix).format('MMMM D, YYYY')
}

export const dateTimeago = (date: Date) => {
  return moment(date).fromNow()
}

export const timeFormatter = (unix: number) => {
  return moment(unix).format('LT')
}

export const timeFormatter24h = (unix: number) => {
  return moment(unix).format('HH:mm')
}

export const timeDateFormatter = (unix: number) => {
  return moment(unix).format('dddd, L, H:mm:ss')
}

export const monthFormatter = (unix: number) => {
  return moment(unix).format('MMM/YYYY')
}

export const yearFormatter = (unix: number) => {
  return moment(unix).format('YYYY')
}

// export const matchDayFormatter = (unix: number) => {
//   return moment(unix).format
// }
