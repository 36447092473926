import {AxiosError} from 'axios'
import {ApiFinance} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {TransactionResultInterface} from '../interfaces/transaction-result.interface'
import {TransactionSingleResultInterface} from '../interfaces/transaction-single-result.interface'

export interface GetAllTransactionParam {
  page?: number
  size?: number
  transaction_type?: string
}

export const GetAllTransaction = async (
  params: GetAllTransactionParam
): Promise<TransactionResultInterface> => {
  try {
    let response = await ApiFinance.get('/transactions', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: TransactionResultInterface = a.response?.data
    return response
  }
}

export const ShowTransaction = async (id: string): Promise<TransactionSingleResultInterface> => {
  try {
    let response = await ApiFinance.get('/transactions/' + id)
    return response.data as TransactionSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TransactionSingleResultInterface = a.response?.data
    return response
  }
}
export const ShowTransactionDonationId = async (
  donationId: string
): Promise<TransactionSingleResultInterface> => {
  try {
    let response = await ApiFinance.get('/transactions-donation-id/' + donationId)
    return response.data as TransactionSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TransactionSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitTransaction = async (form: any): Promise<TransactionSingleResultInterface> => {
  try {
    let response = await ApiFinance.post('/transactions', form)
    return response.data as TransactionSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TransactionSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateTransaction = async (
  id: string,
  form: any
): Promise<TransactionSingleResultInterface> => {
  try {
    let response = await ApiFinance.put('/transactions/' + id, form)
    return response.data as TransactionSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TransactionSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteTransaction = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiFinance.delete('/transactions/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
