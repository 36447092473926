import {useEffect, useState} from 'react'
import {useProfitAndLossStore} from '../states/profit-and-loss.state'
import {KTCard} from '../../../../../../_metronic/helpers'
import moment from 'moment'
import {Input, InputDatePicker, SelectWithText} from '../../../../forms/components/input.component'
import {LoadingComponent} from '../../../crm/properties-management/components/loading.component'
import {generateAccountingFormat} from '../../../../../helpers/generate-currency-format'
import {useProgramStore} from '../../../program/program/states/program.state'
import {SelectData} from '../../../../../interfaces/form-action-interfaces'
import {toast} from 'react-toastify'

const ProfitAndLossListComponent = () => {
  const {resetPnL, getProfitAndLosss, profitAndLossLoading, profitAndLossPrograms, accountTypes} =
    useProfitAndLossStore()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [programIds, setProgramIds] = useState<SelectData[]>([])
  const {getPrograms, programSelectDatas} = useProgramStore()

  useEffect(() => {
    getPrograms({page: 0, size: 10, title: ''})
    return () => {
      resetPnL()
    }
  }, [])

  useEffect(() => {
    console.log('zxczxc', programIds)
  }, [programIds])

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Report Parameter</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Report Parameter</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col-md-6'>
              <InputDatePicker
                id={'start_date'}
                title={'Start Date'}
                name={'start_date'}
                type={'date'}
                value={startDate}
                onChangeDate={(e) => {
                  setStartDate(e)
                }}
              />
            </div>
            <div className='col-md-6'>
              <InputDatePicker
                id={'end_date'}
                title={'End Date'}
                name={'end_date'}
                type={'date'}
                value={endDate}
                onChangeDate={(e) => {
                  setEndDate(e)
                }}
              />
            </div>
            <div className='col-md-6'>
              {/* <Input
                id={'program_ids'}
                title={'Programs'}
                name={'program_ids'}
                type={'text'}
                value={programIds}
                onChange={(e) => setProgramIds(e.target.value)}
              /> */}
              <SelectWithText
                id={'program_ids'}
                title={'Programs'}
                name={'program_ids'}
                type={'text'}
                value={programIds}
                selectData={programSelectDatas}
                isMulti={true}
                onChangeSelectWithText={(e) => {
                  const selectDatas = e as SelectData[]
                  setProgramIds(selectDatas)
                }}
              />
              {/* <SelectWithText
                id={'chart_of_account_id'}
                title={'COA'}
                placeholder={'COA...'}
                name={'chart_of_account_id'}
                type={'select-with-text'}
                value={{
                  label: `${selectedCoa?.coa_code ?? ''} - ${selectedCoa?.name ?? ''}`,
                  value: selectedCoa?.id,
                }}
                selectData={coaSelectDatas}
                onChangeSelectWithText={(e) => {
                  if (e !== null) {
                    const coa = coaItems.find((el) => el.id === e.value)
                    setSelectedCoa(coa)
                  }
                }}
              /> */}
            </div>
            <div className='col-md-12'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={(e) => {
                  e.preventDefault()
                  // navigate('/apps/finance/general-ledger-management/general-ledger-form')
                  if (programIds?.length > 0) {
                    getProfitAndLosss({
                      start_date: moment(startDate).format('YYYY-MM-DD') ?? '',
                      end_date: moment(endDate).format('YYYY-MM-DD') ?? '',
                      program_ids: programIds,
                    })
                    return
                  }

                  toast.error('Please choose program at least 1')
                }}
              >
                Show
              </button>
            </div>
          </div>
        </div>
      </KTCard>
      <KTCard className='mt-6'>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Activity Report</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Activity Report</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {profitAndLossLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-string table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Description</th>
                  {profitAndLossPrograms?.map((item, index) => (
                    <th key={item.program_id} className='text-end'>
                      {item.program_name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {accountTypes?.map((item, index) => (
                  <>
                    <tr>
                      <td className={'fw-bold'} colSpan={profitAndLossPrograms.length + 1}>
                        {item.name}
                      </td>
                    </tr>
                    {item.chart_of_account_profit_and_loss?.map((iitem, iindex) => (
                      <tr key={iindex}>
                        <td>{iitem.name}</td>
                        {profitAndLossPrograms?.map((iiitem, iiindex) => (
                          <td key={iiindex} className='text-end'>
                            {generateAccountingFormat(
                              iiitem.account_type_profit_and_loss
                                ?.find((el) => el.id === item.id)
                                ?.chart_of_account_profit_and_loss?.find((el) => el.id === iitem.id)
                                ?.amount ?? 0
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr>
                      <td>{`Total ${item.name}`}</td>
                      {profitAndLossPrograms?.map((iiitem, iiindex) => (
                        <td key={iiindex} className='text-end fw-bold'>
                          {generateAccountingFormat(
                            iiitem.account_type_profit_and_loss?.find((el) => el.id === item.id)
                              ?.total_amount ?? 0
                          )}
                        </td>
                      ))}
                    </tr>
                    {item.default_coa_code[0] === '5' ? (
                      <tr>
                        <td className='fw-bold'>Items</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {item.default_coa_code[0] === '5' ? (
                      profitAndLossPrograms?.map((iiitem, iiindex) =>
                        iiitem.items?.map((iiiitem, iiiiindex) => (
                          <tr>
                            <td key={iiindex} className='text-start'>
                              {iiiitem.name}
                            </td>
                            {profitAndLossPrograms?.map((iiiiitem, iiiiindex) => (
                              <td key={iiiiindex} className='text-end'>
                                {iiitem?.program_id === iiiiitem.program_id
                                  ? generateAccountingFormat(
                                      iiitem.items?.find((el) => el.id === iiiitem.id)?.quantity *
                                        iiitem.items?.find((el) => el.id === iiiitem.id)?.amount
                                    )
                                  : 0}
                              </td>
                            ))}
                          </tr>
                        ))
                      )
                    ) : (
                      <></>
                    )}
                  </>
                ))}
                <tr>
                  <td className='fw-bold text-start'>Total Sisa Penyaluran</td>
                  {profitAndLossPrograms?.map((iiitem, iiindex) => (
                    <td key={iiindex} className='text-end fw-bold'>
                      {generateAccountingFormat(iiitem.remaining_balance ?? 0)}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </KTCard>
    </>
  )
}

const ProfitAndLossListWrapper = () => {
  return (
    <>
      <ProfitAndLossListComponent />
    </>
  )
}

export default ProfitAndLossListWrapper
