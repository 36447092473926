import { AxiosError } from "axios";
import { ApiWhatsappGateway } from "../../../../../api/api";
import { GeneralInterface } from "../../../../../interfaces/general.interface";
import { GenerateQrCodeResultInterface } from "../interfaces/generate-qr-code-result.interface";

export const GenerateQRCodeWhatsapp = async (number: string): Promise<GenerateQrCodeResultInterface> => {
  try {
    const response = await ApiWhatsappGateway.post(
      '/generate-qr',
      {
        api_key: process.env.REACT_APP_WHATSAPP_API_KEY,
        number: number
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: GenerateQrCodeResultInterface = a.response?.data;
    return response;
  }
}

export const SendMessageWhatsapp = async (sender: string, number: string, message: string): Promise<GeneralInterface> => {
  try {
    const response = await ApiWhatsappGateway.post(
      '/send-message',
      {
        "api_key": process.env.REACT_APP_WHATSAPP_API_KEY,
        "sender": sender,
        "number": number,
        "message": message
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}

export const SendMessageMediaWhatsapp = async (
  sender: string,
  number: string,
  type: string,
  url: string,
  message: string,
  caption: string,
) => {
  try {
    const response = await ApiWhatsappGateway.post(
      '/send-media',
      {
        "api_key": process.env.REACT_APP_WHATSAPP_API_KEY,
        "type": type,
        "sender": sender,
        "url": url,
        "number": number,
        "message": message,
        "caption": caption
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}

export const SendMessageButtonWhatsapp = async (
  sender: string,
  number: string,
  type: string,
  url: string,
  message: string,
  buttons: string[],
  footer: string,
) => {
  try {
    const response = await ApiWhatsappGateway.post(
      '/send-button-v2',
      {
        "api_key": process.env.REACT_APP_WHATSAPP_API_KEY,
        "sender": sender,
        "number": number,
        "buttons": buttons,
        "message": message,
        "type": type,
        "url": url,
        "footer": footer,
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}

interface SendMessageListWhatsappProps {
  sender: string;
  number: string;
  lists: string[];
  message: string;
  footer: string;
  name: string;
  title: string;
  url: string;
  type: string;
}

export const SendMessageListWhatsapp = async ({
  sender,
  number,
  lists,
  message,
  footer,
  name,
  title,
  type,
  url,
}: SendMessageListWhatsappProps) => {
  try {
    const response = await ApiWhatsappGateway.post(
      '/send-list-v2',
      {
        "api_key": process.env.REACT_APP_WHATSAPP_API_KEY,
        "sender": sender,
        "number": number,
        "lists": lists,
        "message": message,
        "footer": footer,
        "name": name,
        "title": title,
        "type": type,
        "url": url,
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}