import {createContext, useEffect, useState} from 'react'
import {ProgramData} from '../../../program/program/interfaces/program-result.interface'
import {ShowProgram} from '../../../program/program/providers/program.provider'
import {TransactionData} from '../../transactions/interfaces/transaction-result.interface'

export type TransactionPaymentContextState = {
  program?: ProgramData
  transaction?: TransactionData
}

export const TransactionPaymentContext = createContext<TransactionPaymentContextState | null>(null)

interface TransactionPaymentProviderProps {
  programId: string
  transaction: TransactionData
  children: React.ReactNode
}

export const TransactionPaymentContextProvider = ({
  programId,
  transaction,
  children,
}: TransactionPaymentProviderProps) => {
  const [program, setProgram] = useState<ProgramData>()

  const getProgram = async (programId: string) => {
    if (programId !== '') {
      const response = await ShowProgram(programId)
      if (response.status) {
        setProgram(response.data)
      }
    }
  }

  useEffect(() => {
    getProgram(programId)
  }, [programId])

  return (
    <TransactionPaymentContext.Provider
      value={{
        program,
        transaction,
      }}
    >
      {children}
    </TransactionPaymentContext.Provider>
  )
}
