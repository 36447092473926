import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {dateFinanceFormatter} from '../../../../../../_metronic/helpers/datetime-formatter'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import {TransactionPaymentData} from '../interfaces/transaction-payment-result.interface'
import {
  TransactionPaymentContext,
  TransactionPaymentContextProvider,
  TransactionPaymentContextState,
} from '../states/transaction-payment.context'
import {
  useTransactionPaymentFormStore,
  useTransactionPaymentStore,
} from '../states/transaction-payment.state'
import {TransactionData} from '../../transactions/interfaces/transaction-result.interface'
import {useTransactionFormStore, useTransactionStore} from '../states/transaction-payment-v2.state'

interface Props {
  transaction: TransactionData
}

const TransactionPaymentItemComponent: React.FC<Props> = ({transaction}) => {
  return (
    <TransactionPaymentContextProvider
      programId={transaction?.program_id}
      transaction={transaction}
    >
      <TransactionPaymentItemComponentState />
    </TransactionPaymentContextProvider>
  )
}

const TransactionPaymentItemComponentState = () => {
  const {program, transaction} =
    useContext<TransactionPaymentContextState>(TransactionPaymentContext)
  const {setSelectedTransaction} = useTransactionFormStore()
  const {onDelete} = useTransactionStore()
  const navigate = useNavigate()

  return (
    <tr>
      <td>{transaction?.transaction_code}</td>
      <td>{dateFinanceFormatter(Date.parse(transaction?.posted_date?.toString()))}</td>
      <td>
        {
          transaction?.transaction_accounts?.find((el) => el.position === 'Credit')
            ?.chart_of_account?.name
        }
      </td>
      <td>{program?.title ?? '-'}</td>
      <td>{transaction?.remarks}</td>
      <td>
        {generateCurrencyFormat(
          transaction?.transaction_accounts?.find((el) => el.position === 'Credit')?.amount
        )}
      </td>
      <td>
        <a
          href={`#`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={(e) => {
            e.preventDefault()
            setSelectedTransaction(transaction)
            navigate('/apps/finance/payment-management/payments-form', {
              state: transaction,
            })
          }}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={(e) => {
            e.preventDefault()
            onDelete(transaction?.id, {page: 0, size: 10, transaction_type: 'Payment'})
          }}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </td>
    </tr>
  )
}

export default TransactionPaymentItemComponent
