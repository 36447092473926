import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import DashboardWrapper from '../modules/apps/dashboard/screens/dashboard.screen'
import TransactionPaymentPage from '../modules/apps/finance/transaction-payments/screens/transaction-payment.screen'
import TransactionDistributionPage from '../modules/apps/finance/transaction-distributions/screens/transaction-distribution.screen'
import ProfitandLossPage from '../modules/apps/finance/profit-and-loss/screens/profit-and-loss.screen'
import MasterExpensePage from '../modules/apps/finance/master-expenses/screens/master-expense.screen'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/apps/auth/user-management/UsersPage'))
  const ProgramPage = lazy(() => import('../modules/apps/program/program/screens/program.screen'))
  const CompanyPage = lazy(() => import('../modules/company/screens/company.screen'))
  const RolePage = lazy(() => import('../modules/apps/auth/role-management/screens/role.screen'))
  const DonationPage = lazy(
    () => import('../modules/apps/program/donation/screens/donation.screen')
  )
  const AccountTypePage = lazy(
    () => import('../modules/apps/finance/account-types/screens/account-types.screen')
  )
  const ChartOfAccountPage = lazy(
    () => import('../modules/apps/finance/chart-of-accounts/screens/chart-of-account.screen')
  )
  const DonationTypePage = lazy(
    () => import('../modules/apps/program/donation-type/screens/donation-type.screen')
  )
  const TransactionReceivePage = lazy(
    () => import('../modules/apps/finance/transaction-receives/screens/transaction-receive.screen')
  )
  const TransactionDistributionPage = lazy(
    () =>
      import(
        '../modules/apps/finance/transaction-distributions/screens/transaction-distribution.screen'
      )
  )
  const TransferBankPage = lazy(
    () => import('../modules/apps/finance/transfer-banks/screens/transfer-bank.screen')
  )
  const GeneralLedgerPage = lazy(
    () => import('../modules/apps/finance/general-ledgers/screens/general-ledger.screen')
  )
  const BankMutationPage = lazy(
    () => import('../modules/apps/finance/bank-mutations/screens/bank-mutations.screen')
  )
  const BalanceSheetPage = lazy(
    () => import('../modules/apps/finance/balance-sheet/screens/balance-sheet.screen')
  )
  const LaporanPerubahanAsetPage = lazy(
    () =>
      import('../modules/apps/finance/laporan-perubahan-aset/screens/laporan-perubahan-aset.screen')
  )
  const PeriodePage = lazy(() => import('../modules/apps/finance/periodes/screens/periodes.screen'))
  const DepartmentPage = lazy(
    () => import('../modules/apps/finance/departments/screens/departments.screen')
  )
  const DivisionPage = lazy(
    () => import('../modules/apps/finance/divisions/screens/division.screen')
  )
  const MasterIncomePage = lazy(
    () => import('../modules/apps/finance/master-incomes/screens/master-income.screen')
  )
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/role-management/*'
          element={
            <SuspensedView>
              <RolePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/program/program-management/*'
          element={
            <SuspensedView>
              <ProgramPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/donation/donation-management/*'
          element={
            <SuspensedView>
              <DonationPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/company-management/*'
          element={
            <SuspensedView>
              <CompanyPage />
            </SuspensedView>
          }
        />
        <Route
          path='/master/finance/account-types-management/*'
          element={
            <SuspensedView>
              <AccountTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='/master/finance/chart-of-accounts-management/*'
          element={
            <SuspensedView>
              <ChartOfAccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='/master/finance/donation-type-management/*'
          element={
            <SuspensedView>
              <DonationTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/finance/receiving-management/*'
          element={
            <SuspensedView>
              <TransactionReceivePage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/finance/distributing-management/*'
          element={
            <SuspensedView>
              <TransactionDistributionPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/finance/payment-management/*'
          element={
            <SuspensedView>
              <TransactionPaymentPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/finance/transfer-bank-management/*'
          element={
            <SuspensedView>
              <TransferBankPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/report/general-ledger-management/*'
          element={
            <SuspensedView>
              <GeneralLedgerPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/report/profit-and-loss-management/*'
          element={
            <SuspensedView>
              <ProfitandLossPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/report/balance-sheet-management/*'
          element={
            <SuspensedView>
              <BalanceSheetPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/report/laporan-perubahan-aset-management/*'
          element={
            <SuspensedView>
              <LaporanPerubahanAsetPage />
            </SuspensedView>
          }
        />
        <Route
          path='/master/finance/periode-management/*'
          element={
            <SuspensedView>
              <PeriodePage />
            </SuspensedView>
          }
        />
        <Route
          path='/master/finance/department-management/*'
          element={
            <SuspensedView>
              <DepartmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='/master/finance/division-management/*'
          element={
            <SuspensedView>
              <DivisionPage />
            </SuspensedView>
          }
        />
        <Route
          path='/master/finance/master-income-management/*'
          element={
            <SuspensedView>
              <MasterIncomePage />
            </SuspensedView>
          }
        />
        <Route
          path='/master/finance/master-expense-management/*'
          element={
            <SuspensedView>
              <MasterExpensePage />
            </SuspensedView>
          }
        />
        <Route
          path='/master/finance/bank-mutations-management/*'
          element={
            <SuspensedView>
              <BankMutationPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
