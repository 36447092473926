import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {ChartOfAccount} from '../../affected-accounts/interfaces/affected-account-result.interface'
import {ChartOfAccountSingleResultInterface} from '../interfaces/chart-of-account-single-result.interface'
import {
  ChartOfAccountData,
  ChartOfAccountTreeResultInterface,
} from '../interfaces/chart-of-account-tree-result.interface'
import {
  DeleteChartOfAccount,
  GetAllChartOfAccount,
  GetAllChartOfAccountAsset,
  GetAllChartOfAccountExpense,
  GetAllChartOfAccountParam,
  GetAllChartOfAccountPayable,
  GetAllChartOfAccountReceivable,
  GetAllChartOfAccountTree,
  ShowChartOfAccountByCode,
  SubmitChartOfAccount,
  UpdateChartOfAccount,
} from '../providers/chart-of-account.provider'
import {dateQueryFormatter} from '../../../../../../_metronic/helpers/datetime-formatter'
import {SubmitTransaction} from '../../transactions/providers/transaction.provider'
import {GetAllDepartment} from '../../departments/providers/department.provider'
import {GetAllDivision} from '../../divisions/providers/division.provider'

interface ChartOfAccountState {
  coaLoading: boolean
  coaTree: ChartOfAccountTreeResultInterface
  coaItems: ChartOfAccount[]
  coaExpenses: ChartOfAccount[]
  coaSelectDatas: SelectData[]
  coaTransferBankSelectData: SelectData[]
  coaReceivableSelectData: SelectData[]
  coaPayableSelectData: SelectData[]
  coaExpenseSelectData: SelectData[]
  getCoaTree: (query: string) => void
  getCoas: (params: GetAllChartOfAccountParam) => void
  getCoasAsset: () => void
  getCoasReceivable: () => void
  getCoasPayable: () => void
  getCoasExpense: () => void
  onDelete: (id: string) => void
}

export const useChartOfAccountStore = create<ChartOfAccountState>((set, get) => ({
  coaLoading: false,
  coaTree: undefined,
  coaItems: [],
  coaExpenses: [],
  coaSelectDatas: [],
  coaTransferBankSelectData: [],
  coaReceivableSelectData: [],
  coaPayableSelectData: [],
  coaExpenseSelectData: [],
  getCoas: async (params: GetAllChartOfAccountParam) => {
    set({coaLoading: true})
    const response = await GetAllChartOfAccount(params)
    if (response.status) {
      if (response.data.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: `${item.coa_code} - ${item.name}`,
            value: item.id,
          })
        }
        set({coaItems: response.data.items, coaSelectDatas: transformDatas})
      } else {
        set({coaItems: [], coaSelectDatas: []})
      }
    } else {
      set({coaItems: [], coaSelectDatas: []})
    }
    set({coaLoading: false})
  },
  getCoaTree: async (query: string) => {
    set({coaLoading: true})
    const response = await GetAllChartOfAccountTree(query)
    if (response.status) {
      set({coaTree: response})
    } else {
      set({coaTree: undefined})
    }
    set({coaLoading: false})
  },
  getCoasAsset: async () => {
    set({coaLoading: true})
    const response = await GetAllChartOfAccountAsset()
    if (response.status) {
      if (response.data?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data) {
          transformDatas.push({
            label: `${item.coa_code} - ${item.name}`,
            value: item.id,
          })
        }
        set({coaTransferBankSelectData: transformDatas})
      } else {
        set({coaTransferBankSelectData: []})
      }
    } else {
      set({coaTransferBankSelectData: []})
    }
    set({coaLoading: false})
  },
  getCoasReceivable: async () => {
    set({coaLoading: true})
    const response = await GetAllChartOfAccountReceivable()
    if (response.status) {
      if (response.data?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data) {
          transformDatas.push({
            label: `${item.coa_code} - ${item.name}`,
            value: item.id,
          })
        }
        set({coaReceivableSelectData: transformDatas})
      } else {
        set({coaReceivableSelectData: []})
      }
    } else {
      set({coaReceivableSelectData: []})
    }
    set({coaLoading: false})
  },
  getCoasPayable: async () => {
    set({coaLoading: true})
    const response = await GetAllChartOfAccountPayable()
    if (response.status) {
      if (response.data?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data) {
          transformDatas.push({
            label: `${item.coa_code} - ${item.name}`,
            value: item.id,
          })
        }
        set({coaPayableSelectData: transformDatas})
      } else {
        set({coaPayableSelectData: []})
      }
    } else {
      set({coaPayableSelectData: []})
    }
    set({coaLoading: false})
  },
  getCoasExpense: async () => {
    set({coaLoading: true})
    const response = await GetAllChartOfAccountExpense()
    if (response.status) {
      if (response.data?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data) {
          transformDatas.push({
            label: `${item.coa_code} - ${item.name}`,
            value: item.id,
          })
        }
        set({coaExpenseSelectData: transformDatas, coaExpenses: response.data})
      } else {
        set({coaExpenseSelectData: [], coaExpenses: []})
      }
    } else {
      set({coaExpenseSelectData: [], coaExpenses: []})
    }
    set({coaLoading: false})
  },
  onDelete: async (id: string) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === false) return
    set({coaLoading: true})
    const response = await DeleteChartOfAccount(id)
    if (response.status) {
      toast.success(response.message)
    } else {
      toast.error(response.message)
    }
    get().getCoaTree('')
    set({coaLoading: false})
  },
}))

const formInitialState = {
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface ChartOfAccountFormState {
  field?: ChartOfAccountData
  setField?: (item: ChartOfAccountData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useChartOfAccountFormStore = create<ChartOfAccountFormState>((set, get) => ({
  field: undefined,
  setField: (item: ChartOfAccountData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    const parentId = formParam?.coaParentId
    const parent = formParam?.coaParent
    const accountTypeId = formParam?.accountTypeId
    const accountType = formParam?.accountType
    const coaCode = formParam?.coaCode
    const totalCoaChilds = formParam?.totalCoaChilds

    forms = [
      {
        id: 'name',
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: get().field?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'coa_code',
        title: `COA Code`,
        placeholder: `COA Code...`,
        type: 'text',
        name: 'coa_code',
        value:
          get().field?.coa_code ??
          `${+coaCode}0${(totalCoaChilds === undefined ? 0 : +totalCoaChilds) + 1}`,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'is_available_on_transaction',
        title: 'Tersedia untuk transfer bank?',
        placeholder: 'Tersedia untuk transfer bank?...',
        type: 'checkbox',
        name: 'is_available_on_transaction',
        value: get().field?.is_available_on_transaction ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'is_available_on_receivable',
        title: 'Tersedia untuk penerimaan (debit)?',
        placeholder: 'Tersedia untuk penerimaan (debit)?...',
        type: 'checkbox',
        name: 'is_available_on_receivable',
        value: get().field?.is_available_on_receivable ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'is_available_on_payable',
        title: 'Tersedia untuk pengeluaran (kredit)?',
        placeholder: 'Tersedia untuk pengeluaran (kredit)?...',
        type: 'checkbox',
        name: 'is_available_on_payable',
        value: get().field?.is_available_on_payable ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'is_group',
        title: 'Is Group?',
        placeholder: 'Is Group?...',
        type: 'checkbox',
        name: 'is_group',
        value: get().field?.is_group ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'parent',
        title: 'Parent COA',
        placeholder: 'Parent COA...',
        type: 'text',
        name: 'parent',
        value: parent ?? '',
        disabled: true,
        required: false,
      },
      {
        id: 'account_type',
        title: 'Account Type',
        placeholder: 'Account Type...',
        type: 'text',
        name: 'account_type',
        value: accountType ?? '',
        disabled: true,
        required: false,
      },
      {
        id: 'account_type_id',
        title: 'Account Type ID',
        placeholder: 'Account Type ID...',
        type: 'text',
        name: 'account_type_id',
        value: accountTypeId ?? '',
        disabled: true,
        required: false,
        hidden: true,
      },
      {
        id: 'parent_id',
        title: 'Parent ID',
        placeholder: 'Parent ID...',
        type: 'text',
        name: 'parent_id',
        value: parentId ?? '',
        disabled: true,
        required: false,
        hidden: true,
      },
    ]
    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    set({submitDone: false, formLoading: false})
    let form = {}

    // check required field
    let requiredError = false
    for (const item of get().forms) {
      if (item.required === true && item.value === '') {
        toast.error(`${item.title} is required`)
        requiredError = true
      }
    }

    if (requiredError === true) return

    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    console.log(form)
    // return;

    let res: ChartOfAccountSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitChartOfAccount(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateChartOfAccount(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.error)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))

export interface ChartOfAccountStartingBalanceFormState {
  sbField?: ChartOfAccountData
  setSbField?: (item: ChartOfAccountData) => void
  sbForms?: FormInput[]
  setSbForms?: (index: number, value: any) => void
  generateSbForms: (formParam: FormProps) => void
  setSbFormSelectData: (index: number, selectData: SelectData[]) => void
  sbFormParam: FormProps
  sbReset: () => void
  sbFormLoading?: boolean
  sbSubmitDone?: boolean
  onSbSubmit: () => void
  getEquityAccount?: () => void
  equityCoa?: ChartOfAccountData
}

export const useCoaStartingBalanceFormStore = create<ChartOfAccountStartingBalanceFormState>(
  (set, get) => ({
    getEquityAccount: async () => {
      const response = await ShowChartOfAccountByCode('300001')
      if (response.status) {
        set({equityCoa: response.data})
      } else {
        set({equityCoa: undefined})
        toast.error('Equitas Saldo Awal not found')
      }
    },
    equityCoa: undefined,
    sbField: undefined,
    setSbField: (item: ChartOfAccountData) => {
      set({sbField: item})
    },
    sbForms: [],
    setSbForms: (index: number, value: any) => {
      const forms = [...get().sbForms]
      forms[index] = {
        ...forms[index],
        value: value,
      }
      set({sbForms: forms})
    },
    generateSbForms: async (formParam: FormProps) => {
      set({sbFormParam: formParam})
      let forms: FormInput[] = []

      const departments = await GetAllDepartment({page: 0, size: 100})
      const divisions = await GetAllDivision({page: 0, size: 100})

      const departmentSelectData: SelectData[] = []
      for (const department of departments?.data?.items) {
        departmentSelectData.push({
          label: `${department.department_name} - ${department.department_type}`,
          value: `${department.id}`,
        })
      }
      const divisionSelectData: SelectData[] = []
      for (const division of divisions?.data?.items) {
        divisionSelectData.push({
          label: `${division.division_name} - ${division.department_name}`,
          value: `${division.id}`,
        })
      }

      forms = [
        {
          id: 'amount',
          title: 'Amount',
          placeholder: 'Amount...',
          type: 'currency',
          name: 'amount',
          value: '',
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        },
        {
          id: 'posted_date',
          title: 'Posted Date',
          placeholder: 'Posted Date...',
          type: 'date',
          name: 'posted_date',
          value: Date.now(),
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        },
        {
          id: 'department_id',
          title: 'Department',
          placeholder: 'Department...',
          type: 'select-with-text',
          name: 'department_id',
          value: {},
          selectData: departmentSelectData,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        },
        {
          id: 'division_id',
          title: 'Divisi',
          placeholder: 'Divisi...',
          type: 'select-with-text',
          name: 'division_id',
          value: {},
          selectData: divisionSelectData,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        },
      ]

      set({sbForms: forms})
    },
    setSbFormSelectData: (index: number, selectData: SelectData[]) => {
      const forms = [...get().sbForms]
      forms[index] = {
        ...forms[index],
        selectData: selectData,
      }
      set({sbForms: forms})
    },
    sbFormParam: undefined,
    sbReset: () => {
      set({
        sbForms: [],
        sbField: undefined,
        sbFormParam: undefined,
        sbFormLoading: undefined,
        sbSubmitDone: undefined,
        equityCoa: undefined,
      })
    },
    sbFormLoading: undefined,
    sbSubmitDone: undefined,
    onSbSubmit: async () => {
      let form = {}

      // check required field
      let requiredError = false
      for (const item of get().sbForms) {
        if (item.required === true && item.value === '') {
          toast.error(`${item.title} is required`)
          requiredError = true
        }
      }

      if (requiredError === true) return

      for (const item of get().sbForms) {
        form = {
          ...form,
          [item.name]: generateFormLogic(item.type, item.value),
        }
      }

      let transactionAccounts = [
        {
          chart_of_account_id: get().sbFormParam?.id,
          amount: form['amount'],
          position: 'Debit',
        },
        {
          chart_of_account_id: get().equityCoa?.id,
          amount: form['amount'],
          position: 'Credit',
        },
      ]

      form = {
        ...form,
        donation_id: '',
        donation_type_id: '',
        program_id: '',
        program_category_id: '',
        transaction_type: 'General Ledger',
        customer_id: '',
        remarks: 'Saldo Awal',
        transaction_accounts: transactionAccounts,
      }

      console.log(form)

      const res = await SubmitTransaction(form)
      if (res.status) {
        toast.success(`Success create starting balance for ${get().sbFormParam?.data?.name}`)
        get().sbReset()
        return
      } else {
        toast.success(`Failed create starting balance for ${get().sbFormParam?.data?.name}`)
        get().sbReset()
        return
      }
    },
  })
)
