import {useLocation, useParams} from 'react-router-dom'
import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {usePeriodeStore} from '../../periodes/states/periode.state'
import {useEffect, useRef, useState} from 'react'
import {dateTimeFormatter} from '../../../../../../_metronic/helpers/datetime-formatter'
import {DivisionItem} from '../../divisions/interfaces/division-result.inteface'
import MoonLoader from 'react-spinners/MoonLoader'
import {Input, InputCurrency, SelectWithText} from '../../../../forms/components/input.component'
import {useUserStore} from '../../../auth/user-management/states/user.state'
import {SelectData} from '../../../../../interfaces/form-action-interfaces'
import clsx from 'clsx'
import {
  MasterExpenseDetailItems,
  useMasterExpenseDetailStore,
} from '../states/master-expense-detail.state'
import {NumberFormatValues, SourceInfo} from 'react-number-format'
const MasterExpenseDetailFormComponent = () => {
  const params = useParams()
  const {getPeriode, periodeItem, monthDiff} = usePeriodeStore()
  const location = useLocation()
  const [division, setDivision] = useState<DivisionItem>()
  const {getUsers, userSelectDatas} = useUserStore()
  const [user, setUser] = useState<SelectData>()
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [grandTotal, setGrandTotal] = useState<number>(0)
  const {
    setDetail,
    details,
    setDetails,
    submitMasterExpenseDetailBulk,
    submitLoading,
    masterDetail,
    reset,
  } = useMasterExpenseDetailStore()

  // detect first render
  const firstRender = useRef(true)

  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  useEffect(() => {
    if (masterDetail !== undefined) {
      setUser({
        label: masterDetail?.user_full_name,
        value: masterDetail?.user_id,
      })
    }
  }, [masterDetail])

  useEffect(() => {
    let gTotal = 0
    for (const detail of details) {
      for (const item of detail.items) {
        gTotal += item.grand_total
      }
    }
    setGrandTotal(gTotal)

    const timeout = setTimeout(() => {
      if (firstRender.current) {
        firstRender.current = false
        return
      }

      submitMasterExpenseDetailBulk()
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [details])

  useEffect(() => {
    console.log(params)
    if (params['id'] !== undefined) {
      getPeriode(params['id'] as string)
      getUsers({
        page: 0,
        size: 1000,
        filters: `[["role_id", "not in", ["7961fc62-ea25-4fd0-840e-945333ff7b14", "04580a88-fada-4313-a922-0377fc3ec1ba"]]]`,
      })
    }
  }, [params])

  useEffect(() => {
    if (location?.state['division'] !== undefined) {
      setDivision(location?.state['division'] as DivisionItem)
    }
  }, [location])

  useEffect(() => {
    if (user !== undefined) {
      setDetail({
        periode_id: periodeItem?.id,
        department_id: division?.department_id,
        division_id: division?.id,
        user_id: user?.value,
        details: [],
      })
    }
  }, [user])

  return (
    <>
      <KTCard>
        <div className='card-header border-1 pt-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Periode {dateTimeFormatter(Date.parse(periodeItem?.start_date?.toString()))} -{' '}
              {dateTimeFormatter(Date.parse(periodeItem?.finish_date?.toString()))}
            </span>
            <span>Department: {division?.department_name}</span>
            <span>Divisi: {division?.division_name}</span>
          </h3>
        </div>
      </KTCard>
      <KTCard className='mt-2'>
        <div className='card-header border-1 pt-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Master Expense by Division</span>
            {submitLoading ? (
              <div
                className='d-flex flex-row'
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MoonLoader size={12} />
                <span className='mx-2' style={{fontSize: 12, fontWeight: 'bold'}}>
                  Saving
                </span>
              </div>
            ) : null}
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='row'>
            <SelectWithText
              title={'PIC'}
              name={'user_id'}
              selectData={userSelectDatas}
              type={'select-with-text'}
              value={user}
              disabled={false}
              id={'user_id'}
              onChangeSelectWithText={(e) => {
                setUser(e)
              }}
              isClearable={true}
              size={6}
              onInputChange={(e) => {
                console.log(e)
              }}
            />
            <InputCurrency
              id={'grand_total'}
              title={'Grand Total'}
              name={'grand_total'}
              type={'currency'}
              placeholder='0'
              value={grandTotal}
              size={6}
              disabled={true}
            />
          </div>
        </div>
      </KTCard>
      {user !== undefined ? (
        <>
          <KTCard className='mt-2'>
            <div className='card-header border-0'>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  {monthDiff?.map((item, index) => {
                    return (
                      <li key={index} className='nav-item'>
                        <a
                          className={clsx(
                            'nav-link text-active-primary me-6',
                            activeIndex === index ? 'active' : 'false'
                          )}
                          href='#'
                          onClick={(e) => {
                            e.preventDefault()
                            setActiveIndex(index)
                          }}
                        >
                          {item.monthName}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </KTCard>
          <KTCard className='mt-2'>
            <div className='card-body'>
              <div className='row'>
                <Input
                  id={'title'}
                  title={'Judul'}
                  name={'title'}
                  type={'text'}
                  value={details?.find((item) => item.month === activeIndex + 1)?.title ?? ''}
                  onChange={(e) => {
                    setDetails({
                      title: e.target?.value ?? '',
                      description:
                        details?.find((item) => item.month === activeIndex + 1)?.description ?? '',
                      month: activeIndex + 1,
                      items: details?.find((item) => item.month === activeIndex + 1)?.items ?? [],
                    })
                  }}
                />
                <Input
                  id={'description'}
                  title={'Keterangan'}
                  name={'description'}
                  type={'text'}
                  value={details?.find((item) => item.month === activeIndex + 1)?.description ?? ''}
                  onChange={(e) => {
                    setDetails({
                      title: details?.find((item) => item.month === activeIndex + 1)?.title ?? '',
                      description: e.target?.value ?? '',
                      month: activeIndex + 1,
                      items: details?.find((item) => item.month === activeIndex + 1)?.items ?? [],
                    })
                  }}
                />
                {details
                  ?.find((item) => item.month === activeIndex + 1)
                  ?.items?.map((item, index) => {
                    return (
                      <div key={index} className='row'>
                        <Input
                          id={'title'}
                          title={'Judul'}
                          name={'title'}
                          type={'text'}
                          value={
                            details?.find((item) => item.month === activeIndex + 1)?.items[index]
                              ?.title ?? ''
                          }
                          onChange={(e) => {
                            let items = [
                              ...details?.find((item) => item.month === activeIndex + 1)?.items,
                            ]
                            items[index].title = e.target?.value
                            setDetails({
                              title:
                                details?.find((item) => item.month === activeIndex + 1)?.title ??
                                '',
                              description:
                                details?.find((item) => item.month === activeIndex + 1)
                                  ?.description ?? '',
                              month: activeIndex + 1,
                              items: items,
                            })
                          }}
                          size={3}
                        />
                        <Input
                          id={'qty'}
                          title={'Qty'}
                          name={'qty'}
                          type={'number'}
                          placeholder='0'
                          value={
                            details?.find((item) => item.month === activeIndex + 1)?.items[index]
                              ?.qty ?? ''
                          }
                          onChange={(e) => {
                            let items = [
                              ...details?.find((item) => item.month === activeIndex + 1)?.items,
                            ]
                            items[index].qty = +e.target?.value
                            items[index].grand_total =
                              items[index].amount * items[index].qty * items[index].vol
                            setDetails({
                              title:
                                details?.find((item) => item.month === activeIndex + 1)?.title ??
                                '',
                              description:
                                details?.find((item) => item.month === activeIndex + 1)
                                  ?.description ?? '',
                              month: activeIndex + 1,
                              items: items,
                            })
                          }}
                          size={2}
                        />
                        <Input
                          id={'vol'}
                          title={'Vol'}
                          name={'vol'}
                          placeholder='0'
                          type={'number'}
                          value={
                            details?.find((item) => item.month === activeIndex + 1)?.items[index]
                              ?.vol ?? ''
                          }
                          onChange={(e) => {
                            let items = [
                              ...details?.find((item) => item.month === activeIndex + 1)?.items,
                            ]
                            items[index].vol = +e.target?.value
                            items[index].grand_total =
                              items[index].amount * items[index].qty * items[index].vol
                            setDetails({
                              title:
                                details?.find((item) => item.month === activeIndex + 1)?.title ??
                                '',
                              description:
                                details?.find((item) => item.month === activeIndex + 1)
                                  ?.description ?? '',
                              month: activeIndex + 1,
                              items: items,
                            })
                          }}
                          size={2}
                        />
                        <InputCurrency
                          id={'nominal'}
                          title={'Nominal'}
                          name={'nominal'}
                          type={'currency'}
                          placeholder='0'
                          value={
                            details?.find((item) => item.month === activeIndex + 1)?.items[index]
                              ?.amount ?? ''
                          }
                          onValueChange={(values: NumberFormatValues, sourceInfo: SourceInfo) => {
                            let items = [
                              ...details?.find((item) => item.month === activeIndex + 1)?.items,
                            ]
                            items[index].amount = +values.floatValue
                            items[index].grand_total =
                              items[index].amount * items[index].qty * items[index].vol
                            setDetails({
                              title:
                                details?.find((item) => item.month === activeIndex + 1)?.title ??
                                '',
                              description:
                                details?.find((item) => item.month === activeIndex + 1)
                                  ?.description ?? '',
                              month: activeIndex + 1,
                              items: items,
                            })
                          }}
                          size={2}
                        />
                        <InputCurrency
                          id={'grand_total'}
                          title={'Jumlah'}
                          name={'grand_total'}
                          type={'currency'}
                          placeholder='0'
                          disabled={true}
                          value={
                            details?.find((item) => item.month === activeIndex + 1)?.items[index]
                              ?.grand_total ?? ''
                          }
                          onValueChange={(values: NumberFormatValues, sourceInfo: SourceInfo) => {
                            let items = [
                              ...details?.find((item) => item.month === activeIndex + 1)?.items,
                            ]
                            items[index].grand_total = +values.floatValue
                            setDetails({
                              title:
                                details?.find((item) => item.month === activeIndex + 1)?.title ??
                                '',
                              description:
                                details?.find((item) => item.month === activeIndex + 1)
                                  ?.description ?? '',
                              month: activeIndex + 1,
                              items: items,
                            })
                          }}
                          size={3}
                        />
                      </div>
                    )
                  })}
                <div className='flex-col'>
                  <button
                    className='btn btn-primary max-w-100px'
                    onClick={(e) => {
                      e.preventDefault()
                      const itemsLength =
                        details?.find((item) => item.month === activeIndex + 1)?.items?.length ?? 0
                      let items: MasterExpenseDetailItems[] = []
                      if (itemsLength === 0) {
                        items.push({
                          title: '',
                          description: '',
                          qty: undefined,
                          vol: undefined,
                          amount: undefined,
                          grand_total: undefined,
                        })
                      } else {
                        items = [...details?.find((item) => item.month === activeIndex + 1)?.items]
                        items.push({
                          title: '',
                          description: '',
                          qty: undefined,
                          vol: undefined,
                          amount: undefined,
                          grand_total: undefined,
                        })
                      }
                      setDetails({
                        title: details?.find((item) => item.month === activeIndex + 1)?.title ?? '',
                        description:
                          details?.find((item) => item.month === activeIndex + 1)?.description ??
                          '',
                        month: activeIndex + 1,
                        items: items,
                      })
                    }}
                  >
                    + Tambah Item
                  </button>
                  <button
                    className='btn btn-danger max-w-100px ms-2'
                    onClick={(e) => {
                      e.preventDefault()
                      const itemsLength =
                        details?.find((item) => item.month === activeIndex + 1)?.items?.length ?? 0
                      let items: MasterExpenseDetailItems[] = []
                      if (itemsLength > 0) {
                        items = [...details?.find((item) => item.month === activeIndex + 1)?.items]
                        items.pop()
                      }
                      setDetails({
                        title: details?.find((item) => item.month === activeIndex + 1)?.title ?? '',
                        description:
                          details?.find((item) => item.month === activeIndex + 1)?.description ??
                          '',
                        month: activeIndex + 1,
                        items: items,
                      })
                    }}
                  >
                    - Delete Last Item
                  </button>
                </div>
              </div>
            </div>
          </KTCard>
        </>
      ) : null}
    </>
  )
}

const MasterExpenseDetailFormWrapper = () => {
  return (
    <>
      <MasterExpenseDetailFormComponent />
    </>
  )
}

export default MasterExpenseDetailFormWrapper
