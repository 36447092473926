import {useEffect} from 'react'
import ReactPaginate from 'react-paginate'
import {useNavigate} from 'react-router-dom'
import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {LoadingComponent} from '../../../crm/properties-management/components/loading.component'
import TransactionPaymentItemComponent from './transaction-payment-item.component'
import {useTransactionStore} from '../states/transaction-payment-v2.state'

const TransactionPaymentListComponent = () => {
  const {getTransactions, transactionLoading, transactionItems, transactionModel} =
    useTransactionStore()
  const navigate = useNavigate()
  // const { setField, field, generateForms, submitDone, formLoading, reset } = useTransactionPaymentFormStore();

  useEffect(() => {
    getTransactions({page: 0, size: 10, transaction_type: 'Payment'})
  }, [])

  // useEffect(() => {
  //   if (submitDone === true && formLoading === false) {
  //     getTransactionPayments({ page: 0, size: 10 });
  //     reset();
  //   }
  // }, [submitDone, formLoading]);

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getTransactions({page: selectedPage, size: 10, transaction_type: 'Payment'})
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Payment</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Payment Management</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                navigate('/apps/finance/payment-management/payments-form')
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Create New Payment
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {transactionLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Account</th>
                  <th>Program</th>
                  <th>Remarks</th>
                  <th>Amount</th>
                  <th className='text-end'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {transactionItems.length === 0 ? (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  transactionItems?.map((item, index) => {
                    return <TransactionPaymentItemComponent key={index} transaction={item} />
                    // return (
                    //   <tr key={index}>
                    //     <td>{item.program_id}</td>
                    //     <td>{item.donation_id}</td>
                    //     <td>{item.customer_id}</td>
                    //     <td className="text-end">
                    // <a
                    //   href={`#`}
                    //   className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    //   onClick={e => {
                    //     e.preventDefault();
                    //     // setField(item);
                    //     // generateForms({
                    //     //   title: 'Update Payment',
                    //     //   subtitle: 'Update Payment',
                    //     //   action: FormAction.UPDATE,
                    //     //   data: item,
                    //     //   id: item.id,
                    //     // });
                    //   }}>
                    //   <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    // </a>
                    // <a
                    //   href="#"
                    //   className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                    //   onClick={e => {
                    //     e.preventDefault();
                    //     onDelete(item.id, {
                    //       page: 0,
                    //       size: 10
                    //     });
                    //   }}>
                    //   <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                    // </a>
                    //     </td>
                    //   </tr>
                    // )
                  })
                )}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={transactionModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      {
        // field !== undefined && <TransactionPaymentModalComponent/>
      }
    </>
  )
}

const TransactionPaymentListWrapper = () => {
  return (
    <>
      <TransactionPaymentListComponent />
    </>
  )
}

export default TransactionPaymentListWrapper
