import {create} from 'zustand'
import {
  CreateBulkMasterExpense,
  GetAllMasterExpense,
  GetAllMasterExpenseParam,
} from '../providers/master-expense.provider'
import {toast} from 'react-toastify'

export interface MasterExpenseTarget {
  department_id: string
  division_id: string
  month: number
  periode_id: string
  amount: number
}

interface MasterExpenseTotalByDivision {
  division_id: string
  total_amount: number
}

interface MasterExpenseTotalByMonth {
  month: number
  total_amount: number
}

interface MasterExpenseState {
  targets: MasterExpenseTarget[]
  setTargets: (target: MasterExpenseTarget) => void
  totalByDivisions: MasterExpenseTotalByDivision[]
  totalByMonth: MasterExpenseTotalByMonth[]
  grandTotal: number

  submitLoading: boolean
  submitMasterExpenseBulk: () => Promise<void>

  getMasterExpenses: (params: GetAllMasterExpenseParam) => Promise<void>
  masterExpenseLoading: boolean

  reset: () => void
}

export const useMasterExpenseStore = create<MasterExpenseState>((set, get) => ({
  reset: () => {
    set({
      targets: [],
      totalByDivisions: [],
      totalByMonth: [],
      grandTotal: 0,
      submitLoading: false,
      masterExpenseLoading: false,
    })
  },
  targets: [],
  totalByDivisions: [],
  totalByMonth: [],
  grandTotal: 0,
  setTargets: (target: MasterExpenseTarget) => {
    const targets = [...get().targets]
    const targetIndex = targets.findIndex(
      (item) => item.division_id === target.division_id && item.month === target.month
    )
    if (targetIndex === -1) {
      targets.push(target)
    } else {
      targets[targetIndex] = target
    }

    // get total by divisions
    const totalByDivisions = [...get().totalByDivisions]
    const totalByDivisionIndex = totalByDivisions.findIndex(
      (item) => item.division_id === target.division_id
    )
    if (totalByDivisionIndex === -1) {
      let totals = 0
      // get target by divisions
      for (const data of targets) {
        if (data.division_id === target.division_id) {
          totals += data.amount
        }
      }
      totalByDivisions.push({total_amount: totals, division_id: target.division_id})
    } else {
      let totals = 0
      // get target by divisions
      for (const data of targets) {
        if (data.division_id === target.division_id) {
          totals += data.amount
        }
      }
      totalByDivisions[totalByDivisionIndex] = {
        total_amount: totals,
        division_id: target.division_id,
      }
    }

    // get total by months
    const totalByMonths = [...get().totalByMonth]
    const totalByMonthIndex = totalByMonths.findIndex((item) => item.month === target.month)
    if (totalByMonthIndex === -1) {
      let totals = 0
      // get target by divisions
      for (const data of targets) {
        if (data.month === target.month) {
          totals += data.amount
        }
      }
      totalByMonths.push({total_amount: totals, month: target.month})
    } else {
      let totals = 0
      // get target by divisions
      for (const data of targets) {
        if (data.month === target.month) {
          totals += data.amount
        }
      }
      totalByMonths[totalByMonthIndex] = {total_amount: totals, month: target.month}
    }

    // get grandTotal
    let grandTotal = 0
    totalByDivisions.map((item) => (grandTotal += item.total_amount))

    set({
      targets: targets,
      totalByDivisions: totalByDivisions,
      totalByMonth: totalByMonths,
      grandTotal: grandTotal,
    })
  },

  submitLoading: false,
  submitMasterExpenseBulk: async () => {
    set({submitLoading: true})
    const response = await CreateBulkMasterExpense(get().targets)
    if (response.status === false) {
      toast.error('Error went submitting data')
    }
    set({submitLoading: false})
  },

  masterExpenseLoading: false,
  getMasterExpenses: async (params: GetAllMasterExpenseParam) => {
    set({masterExpenseLoading: true})
    const response = await GetAllMasterExpense(params)
    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        const targets: MasterExpenseTarget[] = []
        const divisions: string[] = []
        const months: number[] = []
        let grandTotal = 0
        for (const data of response.data?.items) {
          targets.push({
            department_id: data.department_id,
            division_id: data.division_id,
            month: data.month,
            periode_id: data.periode_id,
            amount: data.amount,
          })

          const divisionIndex = divisions.findIndex((item) => item === data.division_id)
          if (divisionIndex === -1) {
            divisions.push(data.division_id)
          }
          const monthIndex = months.findIndex((item) => item === data.month)
          if (monthIndex === -1) {
            months.push(data.month)
          }

          grandTotal += data.amount
        }

        const totalByDivisions: MasterExpenseTotalByDivision[] = []
        for (const div of divisions) {
          let totals = 0
          for (const data of targets) {
            if (div === data.division_id) {
              totals += data.amount
            }
          }
          totalByDivisions.push({total_amount: totals, division_id: div})
        }

        const totalByMonth: MasterExpenseTotalByMonth[] = []
        for (const month of months) {
          let totals = 0
          for (const data of targets) {
            if (month === data.month) {
              totals += data.amount
            }
          }
          totalByMonth.push({total_amount: totals, month: month})
        }

        set({
          targets: targets,
          totalByDivisions: totalByDivisions,
          totalByMonth: totalByMonth,
          grandTotal: grandTotal,
        })
      }
    }
    set({masterExpenseLoading: false})
  },
}))
