import {useEffect} from 'react'
import {NumberFormatValues, SourceInfo} from 'react-number-format'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {
  InputCurrency,
  SelectInput,
  SelectWithText,
} from '../../../../forms/components/input.component'
import {useChartOfAccountStore} from '../../chart-of-accounts/states/chart-of-account.state'
import {useTransactionFormStore} from '../states/transaction-payment-v2.state'

interface Props {
  onCloseModal: () => void
}

const TransactionPaymentDetailModalComponent: React.FC<Props> = ({onCloseModal}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <TransactionPaymentDetailModalHeader onCloseModal={onCloseModal} />

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <TransactionPaymentDetailModalFormWrapper onCloseModal={onCloseModal} />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const TransactionPaymentDetailModalHeader: React.FC<Props> = ({onCloseModal}) => {
  return (
    <div className='modal-header'>
      <h2 className='fw-bold'>{'Data'}</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={onCloseModal}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const TransactionPaymentDetailModalFormWrapper: React.FC<Props> = ({onCloseModal}) => {
  const {
    addTransactionAccounts,
    setSelectedCoa,
    setSelectedPosition,
    setSelectedAmount,
    selectedCoa,
    selectedAmount,
    selectedPosition,
  } = useTransactionFormStore()
  const {getCoas, coaSelectDatas, coaItems} = useChartOfAccountStore()

  useEffect(() => {
    getCoas({page: 0, size: 100000, is_group: false})
  }, [])

  return (
    <>
      <form>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <SelectWithText
            id={'chart_of_account_id'}
            title={'COA'}
            placeholder={'COA...'}
            name={'chart_of_account_id'}
            type={'select-with-text'}
            value={{
              label: `${selectedCoa?.coa_code ?? ''} - ${selectedCoa?.name ?? ''}`,
              value: selectedCoa?.id,
            }}
            selectData={coaSelectDatas}
            onChangeSelectWithText={(e) => {
              if (e !== null) {
                const coa = coaItems.find((el) => el.id === e.value)
                setSelectedCoa(coa)
              }
            }}
          />
          <SelectInput
            id={'position'}
            title={'Position'}
            placeholder={'Position...'}
            name={'position'}
            type={'select'}
            value={'Debit'}
            disabled={true}
            onChangeSelect={(e) => {
              setSelectedPosition(e.target.value)
            }}
            selectData={[
              {
                value: 'Debit',
                label: 'Debit',
              },
              {
                value: 'Credit',
                label: 'Credit',
              },
            ]}
          />
          <InputCurrency
            id={'amount'}
            title={'Nilai'}
            placeholder={'Nilai...'}
            name={'amount'}
            type={'currency'}
            value={selectedAmount}
            onValueChange={(values: NumberFormatValues, sourceInfo: SourceInfo) => {
              setSelectedAmount(values.floatValue)
            }}
          />
        </div>

        <div className='text-center pt--15 mt-10'>
          <button
            type='reset'
            onClick={onCloseModal}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={(e) => {
              e.preventDefault()
              addTransactionAccounts()
              onCloseModal()
            }}
          >
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default TransactionPaymentDetailModalComponent
