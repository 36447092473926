import moment from 'moment'
import {toast} from 'react-toastify'
import {create} from 'zustand'
import {FormInput, SelectData} from '../../../../../interfaces/form-action-interfaces'
import {ProgramData} from '../../../program/program/interfaces/program-result.interface'
import {ShowProgram} from '../../../program/program/providers/program.provider'
import {ChartOfAccount} from '../../affected-accounts/interfaces/affected-account-result.interface'
import {GetAllChartOfAccount} from '../../chart-of-accounts/providers/chart-of-account.provider'
import {
  TransactionAccountData,
  TransactionData,
  TransactionResultInterface,
} from '../../transactions/interfaces/transaction-result.interface'
import {
  GetAllTransactionParam,
  GetAllTransaction,
  DeleteTransaction,
  SubmitTransaction,
  UpdateTransaction,
} from '../../transactions/providers/transaction.provider'

interface TransactionState {
  transactionLoading: boolean
  transactionItems: TransactionData[]
  transactionModel?: TransactionResultInterface
  getTransactions: (params: GetAllTransactionParam) => void
  onDelete: (id: string, params: GetAllTransactionParam) => void
}

export const useTransactionStore = create<TransactionState>((set, get) => ({
  transactionLoading: false,
  transactionItems: [],
  transactionSelectDatas: [],
  transactionModel: undefined,
  getTransactions: async (params: GetAllTransactionParam) => {
    set({transactionLoading: true})
    const response = await GetAllTransaction(params)
    set({transactionModel: response})
    if (response.status) {
      if (response?.data?.items?.length > 0) {
        set({
          transactionItems: response.data.items,
        })
      } else {
        set({
          transactionItems: [],
        })
      }
    } else {
      set({
        transactionItems: [],
      })
    }
    set({transactionLoading: false})
  },
  onDelete: async (id: string, params: GetAllTransactionParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === false) return
    set({transactionLoading: true})
    const response = await DeleteTransaction(id)
    if (response.status) {
      toast.success(response.message)
    } else {
      toast.error(response.message)
    }
    get().getTransactions(params)
    set({transactionLoading: false})
  },
}))

interface TransactionFormState {
  selectedProgram: ProgramData
  setSelectedProgram: (program: ProgramData) => void
  transactionFormReset: () => void
  transactionAccounts: TransactionAccountData[]
  addTransactionAccounts?: () => void
  removeTransactionAccounts?: (index: number) => void
  transactionAccountForms: FormInput[]
  setTransactionAccountForms: (index: number, value: any) => void
  generateTransactionAccountForms?: () => void
  selectedCoa?: ChartOfAccount
  selectedPosition?: string
  selectedAmount?: number
  setSelectedCoa?: (coa: ChartOfAccount) => void
  setSelectedPosition?: (pos: string) => void
  setSelectedAmount?: (amount: number) => void
  postedDate?: Date
  setPostedDate?: (date: Date) => void
  remarks?: string
  setRemarks?: (remarks: string) => void
  document?: string
  setDocument?: (document: string) => void
  onSubmit: (creditCoa: string) => void
  selectedTransaction?: TransactionData
  setSelectedTransaction?: (data: TransactionData) => void
  getSelectedProgramByProgramId?: (programId: string) => void
  submitDone?: boolean
  department?: SelectData
  setDepartment?: (data: SelectData) => void
  division?: SelectData
  setDivision?: (data: SelectData) => void
  status?: SelectData
  setStatus?: (data: SelectData) => void
}

export const useTransactionFormStore = create<TransactionFormState>((set, get) => ({
  selectedProgram: undefined,
  transactionAccounts: [],
  transactionAccountForms: [],
  selectedTransaction: undefined,
  submitDone: undefined,
  setSelectedTransaction: (data: TransactionData) => {
    set({transactionAccounts: data?.transaction_accounts ?? []})
    set({selectedTransaction: data})
  },
  setSelectedProgram: (program: ProgramData) => {
    set({selectedProgram: program})
  },
  getSelectedProgramByProgramId: async (programId: string) => {
    const response = await ShowProgram(programId)
    if (response.status) {
      get().setSelectedProgram(response.data)
    } else {
      get().setSelectedProgram(undefined)
    }
  },
  transactionFormReset: () => {
    set({
      selectedProgram: undefined,
      postedDate: undefined,
      remarks: undefined,
      selectedAmount: undefined,
      selectedCoa: undefined,
      selectedPosition: undefined,
      submitDone: undefined,
    })
  },
  addTransactionAccounts: () => {
    const datas = [...get().transactionAccounts]
    let form: TransactionAccountData = {
      chart_of_account: {
        id: get().selectedCoa?.id,
        coa_code: get().selectedCoa?.coa_code,
        name: get().selectedCoa?.name,
      },
      chart_of_account_id: get().selectedCoa?.id,
      position: 'Debit',
      // position: get().selectedPosition,
      amount: get().selectedAmount,
    }
    datas.push(form)
    set({transactionAccounts: datas})
  },
  removeTransactionAccounts: (index: number) => {
    const datas = [...get().transactionAccounts]
    datas.splice(index, 1)
    set({transactionAccounts: datas})
  },
  setTransactionAccountForms: (index: number, value: any) => {
    const forms = [...get().transactionAccountForms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({transactionAccountForms: forms})
  },
  generateTransactionAccountForms: async () => {
    const response = await GetAllChartOfAccount({page: 0, size: 10000})
    const selectDatas: SelectData[] = []

    for (const item of response?.data?.items) {
      selectDatas.push({
        label: `${item.coa_code} - ${item.name}`,
        value: item.id,
      })
    }

    let forms: FormInput[] = []
    forms = [
      {
        id: 'chart_of_account_id',
        title: 'COA',
        placeholder: 'COA...',
        type: 'select-with-text',
        name: 'chart_of_account_id',
        selectData: selectDatas,
        disabled: false,
        required: true,
      },
      {
        id: 'position',
        title: 'Position',
        placeholder: 'Position...',
        type: 'select',
        name: 'position',
        value: '',
        selectData: [
          {
            value: 'Debit',
            label: 'Debit',
          },
          {
            value: 'Credit',
            label: 'Credit',
          },
        ],
        disabled: false,
        required: true,
      },
      {
        id: 'amount',
        title: 'Nilai',
        placeholder: 'Nilai...',
        type: 'currency',
        name: 'amount',
        disabled: false,
        required: true,
      },
    ]
    set({transactionAccountForms: forms})
  },
  selectedCoa: undefined,
  selectedPosition: 'Debit',
  selectedAmount: undefined,
  setSelectedCoa: (coa: ChartOfAccount) => {
    set({selectedCoa: coa})
  },
  setSelectedPosition: (pos: string) => {
    set({selectedPosition: pos})
  },
  setSelectedAmount: (amount: number) => {
    set({selectedAmount: amount})
  },
  postedDate: undefined,
  setPostedDate: (date: Date) => {
    set({postedDate: date})
  },
  remarks: undefined,
  setRemarks: (remarks: string) => {
    set({remarks: remarks})
  },
  department: undefined,
  setDepartment: (data: SelectData) => {
    set({department: data})
  },
  division: undefined,
  setDivision: (data: SelectData) => {
    set({division: data})
  },
  onSubmit: async (creditCoa: string) => {
    let accounts: TransactionAccountData[] = []
    let creditAmount = 0

    //for credit amounts
    for (const item of get().transactionAccounts) {
      if (item.chart_of_account_id !== creditCoa) {
        if (item.position === 'Credit') {
          creditAmount += item.amount
        }
        if (item.position === 'Debit') {
          creditAmount -= item.amount
        }
      }
    }
    accounts.push({
      chart_of_account: {
        id: creditCoa,
      },
      chart_of_account_id: creditCoa,
      position: 'Credit',
      amount: Math.abs(creditAmount),
    })

    // add debit accounts
    for (const item of get().transactionAccounts) {
      if (item.chart_of_account_id !== creditCoa) {
        accounts.push(item)
      }
    }

    let form = {
      transaction_type: 'Payment',
      program_id: get().selectedProgram?.id ?? '',
      remarks: get().remarks ?? '',
      posted_date: moment(get().postedDate).format('YYYY-MM-DD') ?? '',
      transaction_accounts: accounts ?? [],
      document: get().document,
      status: get().status?.value,
      department_id: get().department?.value,
      division_id: get().division?.value,
    }

    if (get().selectedTransaction === undefined) {
      const res = await SubmitTransaction(form)
      if (res.status) {
        toast.success(res.message)
        set({submitDone: true})
        return
      } else {
        toast.error(res.message)
        set({submitDone: false})
        return
      }
    } else {
      const tr = get().selectedTransaction
      const res = await UpdateTransaction(tr?.id, form)
      if (res.status) {
        toast.success(res.message)
        set({submitDone: true})
        return
      } else {
        toast.error(res.message)
        set({submitDone: false})
        return
      }
    }
  },
  document: undefined,
  setDocument: (doc: string) => {
    set({document: doc})
  },
  status: {label: 'Unpaid', value: 'Unpaid'},
  setStatus: (data: SelectData) => {
    set({status: data})
  },
}))
