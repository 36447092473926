import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {usePeriodeStore} from '../../periodes/states/periode.state'
import {useEffect, useState} from 'react'
import {dateTimeFormatter} from '../../../../../../_metronic/helpers/datetime-formatter'
import {DivisionItem} from '../../divisions/interfaces/division-result.inteface'
import {useMasterExpenseDetailStore} from '../states/master-expense-detail.state'
import ReactPaginate from 'react-paginate'
import {LoadingComponent} from '../../../crm/properties-management/components/loading.component'
import {
  generateAccountingFormat,
  generateCurrencyFormat,
} from '../../../../../helpers/generate-currency-format'

const MasterExpenseDetailListComponent = () => {
  const params = useParams()
  const {getPeriode, periodeItem, monthDiff} = usePeriodeStore()
  const location = useLocation()
  const [division, setDivision] = useState<DivisionItem>()
  const navigate = useNavigate()
  const {getMasterDetails, masterDetailModel, masterDetailLoading, masterDetails, setMasterDetail} =
    useMasterExpenseDetailStore()

  useEffect(() => {
    console.log(params)
    if (params['id'] !== undefined) {
      getPeriode(params['id'] as string)
    }
  }, [params])

  useEffect(() => {
    if (location?.state['division'] !== undefined) {
      const divs = location?.state['division'] as DivisionItem
      const periode = location?.state['periode'] as DivisionItem
      setDivision(divs)
      getMasterDetails({
        page: 0,
        size: 25,
        filters: `[["periode_id", "${periode.id}"], ["and"], ["division_id", "${divs.id}"], ["and"], ["department_id", "${divs.department_id}"]]`,
      })
    }
  }, [location])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getMasterDetails({
      page: selectedPage,
      size: 25,
      filters: `[["periode_id", "${periodeItem.id}"], ["and"], ["division_id", "${division.id}"], ["and"], ["department_id", "${division.department_id}"]]`,
    })
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-1 pt-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Periode {dateTimeFormatter(Date.parse(periodeItem?.start_date?.toString()))} -{' '}
              {dateTimeFormatter(Date.parse(periodeItem?.finish_date?.toString()))}
            </span>
            <span>Department: {division?.department_name}</span>
            <span>Divisi: {division?.division_name}</span>
          </h3>
        </div>
      </KTCard>
      <KTCard className='mt-2'>
        <div className='card-header border-1 pt-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Master Expense by Division</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              List Master Expense by Division
            </span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                navigate('forms', {state: {division: division}})
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Create New Master Expense By Division
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {masterDetailLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>PIC</th>
                  <th>Grand Total</th>
                  <th className='text-end'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {masterDetails.length === 0 ? (
                  <tr>
                    <td colSpan={4}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  masterDetails?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.user_full_name}</td>
                        <td>{generateCurrencyFormat(item?.grand_total ?? 0)}</td>
                        <td className='text-end'>
                          <span
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={(e) => {
                              e.preventDefault()
                              setMasterDetail(item)
                              navigate('forms', {state: {division: division}})
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </span>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className='card-footer'>
          <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            breakLabel={'...'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageCount={masterDetailModel?.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={'pagination pagination-sm m-0 float-right'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
      </KTCard>
    </>
  )
}

const MasterExpenseDetailWrapper = () => {
  return (
    <>
      <MasterExpenseDetailListComponent />
    </>
  )
}

export default MasterExpenseDetailWrapper
