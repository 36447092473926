import {toast} from 'react-toastify'
import {create} from 'zustand'
import {MessageData} from '../interfaces/message-result.interface'
import {RoomData, RoomResultInterface} from '../interfaces/room-result.interface'
import {GetAllMessage} from '../providers/message.provider'
import {CloseRoom, DeleteRoom, GetAllRoom, HandleRoom} from '../providers/room.provider'

export interface RoomMessageState {
  roomLoading: boolean
  messageLoading: boolean
  getRooms: (companyChannelPropertyId: string, status: string) => void
  getRoomsLoadMore?: (status: string) => void
  getMessages: (roomId: string) => void
  roomModel?: RoomResultInterface
  roomItems: RoomData[]
  roomTempItems: RoomData[]
  messageItems: MessageData[]
  activeRoom?: RoomData
  setActiveRoom: (activeRoom: RoomData) => void
  deleteRoom: (activeRoom: RoomData, status: string) => void
  handleRoom: (activeRoom: RoomData, status: string) => void
  closeRoom: (activeRoom: RoomData, status: string) => void
  searchRoom: (query: string) => void
}

export const useRoomMessageStore = create<RoomMessageState>((set, get) => ({
  roomLoading: false,
  messageLoading: false,
  roomItems: [],
  roomTempItems: [],
  messageItems: [],
  activeId: undefined,
  roomModel: undefined,
  searchRoom: (query: string) => {
    const rooms = get().roomTempItems
    const newRooms = rooms.filter(
      (el) =>
        el?.sender?.pushName?.toLowerCase().includes(query.toLowerCase()) ||
        el?.sender?.number?.toLowerCase().includes(query.toLowerCase())
    )
    set({roomItems: newRooms})
  },
  setActiveRoom: (activeRoom: RoomData) => {
    set({activeRoom: activeRoom})
  },
  getRooms: async (companyChannelPropertyId: string, status: string) => {
    set({roomLoading: true})
    const response = await GetAllRoom({
      page: 0,
      size: 10,
      // company_channel_property_id: companyChannelPropertyId,
      status: status,
    })
    set({roomModel: response})
    if (response.status) {
      if (response.data.items?.length > 0) {
        set({roomTempItems: response.data?.items, roomItems: response.data?.items})
      } else {
        set({roomTempItems: [], roomItems: []})
      }
    } else {
      set({roomTempItems: [], roomItems: []})
    }
    set({roomLoading: false})
  },
  getRoomsLoadMore: async (status: string) => {
    set({roomLoading: true})
    const rooms = [...get().roomItems]
    const response = await GetAllRoom({
      page: get().roomModel?.data?.page + 1,
      size: 10,
      // company_channel_property_id: companyChannelPropertyId,
      status: status,
    })
    set({roomModel: response})
    if (response.status) {
      if (response.data.items?.length > 0) {
        rooms.push(...response.data.items)
        set({roomTempItems: response.data?.items, roomItems: rooms})
      }
    }
    set({roomLoading: false})
  },
  getMessages: async (roomId: string) => {
    set({messageLoading: true})
    const response = await GetAllMessage({
      page: 0,
      size: 10,
      room_id: roomId,
    })
    if (response.status) {
      if (response.data.length > 0) {
        setTimeout(() => {
          set({
            messageItems: response.data,
            messageLoading: false,
          })
        }, 2000)
      }
    }
  },
  deleteRoom: async (activeRoom: RoomData, status: string) => {
    set({roomLoading: true})
    const response = await DeleteRoom(activeRoom.id)
    if (response.status) {
      toast.success(response.message)
      get().getRooms(activeRoom.company_channel_property_id, status)
      get().setActiveRoom(undefined)
    } else {
      toast.error(response.message)
    }
    set({roomLoading: false})
  },
  handleRoom: async (activeRoom: RoomData, status: string) => {
    set({roomLoading: true})
    const response = await HandleRoom(activeRoom.id)
    if (response.status) {
      toast.success(response.message)
      get().getRooms(activeRoom.company_channel_property_id, status)
      get().setActiveRoom(undefined)
    } else {
      toast.error(response.message)
    }
    set({roomLoading: false})
  },
  closeRoom: async (activeRoom: RoomData, status: string) => {
    set({roomLoading: true})
    const response = await CloseRoom(activeRoom.id)
    if (response.status) {
      toast.success(response.message)
      get().getRooms(activeRoom.company_channel_property_id, status)
      get().setActiveRoom(undefined)
    } else {
      toast.error(response.message)
    }
    set({roomLoading: false})
  },
}))
