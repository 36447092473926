import {AxiosError} from 'axios'
import {ApiCrm} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {DonationDashboardParam} from '../../../program/donation/providers/donation.provider'
import {CustomerProfileResultInterface} from '../interfaces/customer-profile-result.interface'
import {CustomerResultInterface, CustomerData} from '../interfaces/customer-result.interface'
import {CustomerSegmentResult} from '../interfaces/customer-segment-result.interface'
import {CustomerSingleResultInterface} from '../interfaces/customer-single-result.interface'

export interface GetAllCustomerParam {
  page?: number
  size?: number
  reference?: string
  is_quarantine?: boolean
  search?: string
}

export const GetAllCustomer = async (
  params: GetAllCustomerParam
): Promise<CustomerResultInterface> => {
  try {
    let response = await ApiCrm.get('/customers', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerResultInterface = a.response?.data
    return response
  }
}

export const GetAllCustomerV2 = async (
  params: GetAllCustomerParam
): Promise<CustomerResultInterface> => {
  try {
    let response = await ApiCrm.get('/customers-v2-cached', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerResultInterface = a.response?.data
    return response
  }
}

export const GetAllCustomerAffiliate = async (
  params: GetAllCustomerParam
): Promise<CustomerResultInterface> => {
  try {
    let response = await ApiCrm.get('/customer-affiliate', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerResultInterface = a.response?.data
    return response
  }
}

export const ShowCustomer = async (id: string): Promise<CustomerSingleResultInterface> => {
  try {
    let response = await ApiCrm.get('/customers/' + id)
    return response.data as CustomerSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerSingleResultInterface = a.response?.data
    return response
  }
}

export const ShowCustomerByReference = async (
  reference: string
): Promise<CustomerResultInterface> => {
  try {
    let response = await ApiCrm.get('/customers-by-reference', {
      params: {
        reference_table: reference,
      },
    })
    return response.data as CustomerResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerResultInterface = a.response?.data
    return response
  }
}

export const SubmitCustomer = async (
  form: CustomerData
): Promise<CustomerSingleResultInterface> => {
  try {
    let response = await ApiCrm.post('/customers', form)
    return response.data as CustomerSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateCustomer = async (
  id: string,
  form: CustomerData
): Promise<CustomerSingleResultInterface> => {
  try {
    let response = await ApiCrm.put('/customers/' + id, form)
    return response.data as CustomerSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteCustomer = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete('/customers/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const BulkSortingCustomer = async (
  ids: string[],
  sorts: number[]
): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.put('/customers-sorting', {
      ids: ids,
      sorts: sorts,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const MoveToQuarantine = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.put(`/customers-move-to-quarantine/${id}`)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const MoveToUnquarantine = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.put(`/customers-move-to-unquarantine/${id}`)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalCustomer = async (
  params: DonationDashboardParam
): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.get(`/total-customers`, {
      params: params,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetCustomerSegment = async (): Promise<CustomerSegmentResult> => {
  try {
    let response = await ApiCrm.get(`/customers-segment`)
    return response.data as CustomerSegmentResult
  } catch (e) {
    let a = e as AxiosError
    return a.response?.data as CustomerSegmentResult
  }
}

export const GetCustomerProfile = async (): Promise<CustomerProfileResultInterface> => {
  try {
    let response = await ApiCrm.get(`/customers-profile`)
    return response.data as CustomerProfileResultInterface
  } catch (e) {
    let a = e as AxiosError
    return a.response?.data as CustomerProfileResultInterface
  }
}

export const GetCustomerByAffiliator = async (
  params: GetAllCustomerParam,
  id: string
): Promise<CustomerResultInterface> => {
  try {
    let response = await ApiCrm.get('/customers-by-affiliator/' + id, {params: params})
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerResultInterface = a.response?.data
    return response
  }
}
