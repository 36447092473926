import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {FormattedMessage} from 'react-intl'
import {PeriodeItem, PeriodeResultInterface} from '../interfaces/periode-result.interface'
import {PeriodeSingleResultInterface} from '../interfaces/periode-single-result.interface'
import {
  GetAllPeriodeParam,
  GetAllPeriode,
  DeletePeriode,
  SubmitPeriode,
  UpdatePeriode,
  ShowPeriode,
} from '../providers/periode.provider'
import {dateTimeFormatter} from '../../../../../../_metronic/helpers/datetime-formatter'
import {getMonthDifference, getMonthName} from '../../../../../../_metronic/helpers'

interface MonthDiff {
  month: number
  monthName: string
}

interface PeriodeState {
  periodeLoading: boolean
  periodeItems: PeriodeItem[]
  periodeSelectDatas: SelectData[]
  periodeModel?: PeriodeResultInterface
  getPeriodes: (params: GetAllPeriodeParam) => void
  onDelete: (id: string, params: GetAllPeriodeParam) => void

  periodeItem: PeriodeItem
  monthDiff: MonthDiff[]
  periodeItemLoading: boolean
  getPeriode: (id: string) => Promise<void>
}

export const usePeriodeStore = create<PeriodeState>((set, get) => ({
  periodeLoading: false,
  periodeItems: [],
  periodeSelectDatas: [],
  periodeModel: undefined,
  getPeriodes: async (params: GetAllPeriodeParam) => {
    set({periodeLoading: true})
    const response = await GetAllPeriode(params)
    set({periodeModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: `Periode ${dateTimeFormatter(
              Date.parse(item.start_date.toString())
            )} - ${dateTimeFormatter(Date.parse(item.finish_date.toString()))}`,
            value: item.id,
          })
        }
        set({
          periodeItems: response.data.items,
          periodeSelectDatas: transformDatas,
        })
      } else {
        set({
          periodeItems: [],
          periodeSelectDatas: [],
        })
      }
    } else {
      set({
        periodeItems: [],
        periodeSelectDatas: [],
      })
    }
    set({periodeLoading: false})
  },
  onDelete: async (id: string, params: GetAllPeriodeParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === false) return
    set({periodeLoading: true})
    const response = await DeletePeriode(id)
    if (response.status) {
      toast.success(response.message)
    } else {
      toast.error(response.message)
    }
    get().getPeriodes(params)
    set({periodeLoading: false})
  },

  monthDiff: [],
  periodeItemLoading: false,
  periodeItem: undefined,
  getPeriode: async (id: string) => {
    set({periodeItemLoading: true})
    const response = await ShowPeriode(id)
    if (response.status === true) {
      set({periodeItem: response.data})

      const monthDiff = getMonthDifference(response?.data?.finish_date, response?.data?.start_date)
      const monthDiffs: MonthDiff[] = []
      for (let i = 0; i <= monthDiff; i++) {
        console.log(i)
        monthDiffs.push({
          month: i + 1,
          monthName: getMonthName(i + 1),
        })
      }
      set({monthDiff: monthDiffs})
    }
    set({periodeItemLoading: false})
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface PeriodeFormState {
  field?: PeriodeItem
  setField?: (item: PeriodeItem) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const usePeriodeFormStore = create<PeriodeFormState>((set, get) => ({
  field: undefined,
  setField: (item: PeriodeItem) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'start_date',
        title: 'Start Date',
        placeholder: 'Start Date...',
        type: 'date',
        name: 'start_date',
        value: get().field?.start_date ?? new Date(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'finish_date',
        title: 'Finish Date',
        placeholder: 'Finish Date...',
        type: 'date',
        name: 'finish_date',
        value: get().field?.finish_date ?? new Date(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'status',
        title: 'Status',
        placeholder: 'Status...',
        type: 'select-with-text',
        name: 'status',
        value: {
          value: get().field?.status ?? '',
          label: get().field?.status?.toLocaleUpperCase() ?? '',
        },
        selectData: [
          {value: 'live', label: 'LIVE'},
          {value: 'close', label: 'CLOSE'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]
    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}

    // check required field
    let requiredError = false
    for (const item of get().forms) {
      console.log(item.name, item.value)
      if (item.required === true && (item.value === '' || item.value?.value === '')) {
        toast.error(`${item.title} is required`)
        requiredError = true
      }
    }

    if (requiredError === true) return

    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: PeriodeSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitPeriode(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdatePeriode(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.error)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
