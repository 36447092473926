import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {FormattedMessage} from 'react-intl'
import {DepartmentItem, DepartmentResultInterface} from '../interfaces/department-result.interface'
import {DepartmentSingleResultInterface} from '../interfaces/department-single-result.interface'
import {
  GetAllDepartmentParam,
  GetAllDepartment,
  DeleteDepartment,
  SubmitDepartment,
  UpdateDepartment,
} from '../providers/department.provider'
import {dateTimeFormatter} from '../../../../../../_metronic/helpers/datetime-formatter'

interface DepartmentState {
  departmentLoading: boolean
  departmentItems: DepartmentItem[]
  departmentSelectDatas: SelectData[]
  departmentModel?: DepartmentResultInterface
  getDepartments: (params: GetAllDepartmentParam) => void
  onDelete: (id: string, params: GetAllDepartmentParam) => void
}

export const useDepartmentStore = create<DepartmentState>((set, get) => ({
  departmentLoading: false,
  departmentItems: [],
  departmentSelectDatas: [],
  departmentModel: undefined,
  getDepartments: async (params: GetAllDepartmentParam) => {
    set({departmentLoading: true})
    const response = await GetAllDepartment(params)
    set({departmentModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.department_name,
            value: item.id,
          })
        }
        set({
          departmentItems: response.data.items,
          departmentSelectDatas: transformDatas,
        })
      } else {
        set({
          departmentItems: [],
          departmentSelectDatas: [],
        })
      }
    } else {
      set({
        departmentItems: [],
        departmentSelectDatas: [],
      })
    }
    set({departmentLoading: false})
  },
  onDelete: async (id: string, params: GetAllDepartmentParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === false) return
    set({departmentLoading: true})
    const response = await DeleteDepartment(id)
    if (response.status) {
      toast.success(response.message)
    } else {
      toast.error(response.message)
    }
    get().getDepartments(params)
    set({departmentLoading: false})
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface DepartmentFormState {
  field?: DepartmentItem
  setField?: (item: DepartmentItem) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useDepartmentFormStore = create<DepartmentFormState>((set, get) => ({
  field: undefined,
  setField: (item: DepartmentItem) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'department_name',
        title: 'Department',
        placeholder: 'Department...',
        type: 'text',
        name: 'department_name',
        value: get().field?.department_name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'department_type',
        title: 'Department Type',
        placeholder: 'Department Type...',
        type: 'select-with-text',
        name: 'department_type',
        value: {
          value: get().field?.department_type ?? '',
          label: get().field?.department_type?.toLocaleUpperCase() ?? '',
        },
        selectData: [
          {value: 'profit', label: 'PROFIT'},
          {value: 'cost', label: 'COST'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]
    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}

    // check required field
    let requiredError = false
    for (const item of get().forms) {
      console.log(item.name, item.value)
      if (item.required === true && (item.value === '' || item.value?.value === '')) {
        toast.error(`${item.title} is required`)
        requiredError = true
      }
    }

    if (requiredError === true) return

    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: DepartmentSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitDepartment(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateDepartment(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.error)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
